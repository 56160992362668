import React, { useState } from "react";
import "./ReplyList.css";
import {axiosInstance} from "../../axios";
import { useDelData, useUpdateData, useGetUserId } from "../../hooks/useApi";
const ReplyList = ({
  reply,
  setIsEditing,
  isEditing,
  setEditingIndex,
  editingIndex,
  itemKey,
  boardId
}) => {

  const [userReplyId, setUserReplyId] = useState(reply.user__id);
  const [userId] = useGetUserId();
  const [contents, setContents, delContent] = useDelData("/board/replys/", reply.id,`/board/detail/${boardId}` )
  const updateHandler = async(itemKey) => {
    if(userReplyId == userId){
      setIsEditing(true);
      setEditingIndex(itemKey);
    }else{
        window.alert("수정할 수 없습니다.")
    }
  };

  const [replyContent, setReplyContent]= useState(reply.content)
  const [originalContent, setOriginalContent] = useState(reply.content);
  const updateData = useUpdateData("/board/replys" ,reply.id ,`/board/detail/${boardId}`)
  
  const handleInputChange = (e) =>{
     setReplyContent(e.target.value)
  }
  const cancelHandler = () =>{
    setIsEditing(false);
    setEditingIndex(null);
    setReplyContent(originalContent);
  }

  const CompleteUpdate = async() =>{
    const info = {content: replyContent}
    await updateData(info);
  }

  return (
    <div className="reply-container"  key={itemKey}>
      <div className="d-flex  justify-content-between ">
        <div className="reply-first-content">
          <span className="reply-name">{reply.user__name}</span>
          <span className="reply-day">{reply.created_at.slice(0, 10)}</span>
        </div>
        <div className="reply-button">
          {isEditing && editingIndex === itemKey ? (
            <>
              <span onClick={CompleteUpdate}>완료</span>
              <span
                onClick={cancelHandler}
                style={{ marginLeft: 6 }}
              >
                취소
              </span>
              <span onClick={delContent} style={{ marginLeft: 6 }}>
                삭제
              </span>
            </>
          ) : (
            <>
              <span onClick={() => updateHandler(itemKey)}>수정</span>
              <span onClick={delContent} style={{ marginLeft: 6 }}>
                삭제
              </span>
            </>
          )}
        </div>
      </div>
      <div className="reply-content">
        {isEditing && editingIndex === itemKey ? (
          <>
            <input
              type="text"
              value={replyContent}
              onChange={(e) => handleInputChange(e)}
              className="editInput"
           />
          </>
        ) : (
          reply.content
        )}
      </div>
    </div>
  );
};

export default ReplyList;
