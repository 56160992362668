import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import Menulists from "../../data/Menulist";
import SubMenubar from "../layout/SubMenubar";
import SubImgbar from "../layout/SubImgbar";
import "./QNABoardDetail.css";
import MyA from "../shared/MyA.js";
import { axiosInstance } from "../../axios.js";
import { useRecoilValue } from "recoil";
import { isLoginSelector } from "../../recoil/User";
import { useDelData, useGetUserId } from "../../hooks/useApi.js";
function QNAqnaboardDetail() {
  const location = useLocation();
  const boardId = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );

  const sub_menubar = Menulists.find(
    (m) => m.url == `/${location.pathname.split("/")[1]}`
  );
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );
  const [content, setContent] = useState(null);
  const [boardUserId, setboardUserId] = useState("");
  const [userId, isAdmin] = useGetUserId();
  const navigate = useNavigate();
  const isLogin = useRecoilValue(isLoginSelector);
  const [contents, setContents, delContent] = useDelData(
    "/board/qnaboards",
    boardId.includes("&") ? boardId.split("&")[1] : boardId,
    "/qnaboard"
  );

  async function getContent() {
    if (boardId.includes("&")) {
      const replyId = boardId.split("&");
      const { data } = await axiosInstance.get(
        `board/qnaboards/${replyId[0]}/`
      );
      setContent(data.child[0]);
      setboardUserId(data.child[0].user.id);
    } else {
      const { data } = await axiosInstance.get(`board/qnaboards/${boardId}/`);
      setContent(data);
      setboardUserId(data.user.id);
    }
  }
  useEffect(() => {
    getContent();
    // if(boardId.includes('&')){
    //  async function getChildContent(){
    //   const { data } = await axiosInstance.get(`board/qnaboards/${boardId}/`);
    // setContent(data.child[0]);
    // setboardUserId(data.user.id);
    //   console.log(data)
    //  }
    //  getChildContent();
    // }
  }, []);
  return (
    <div className="qnaboardDetail">
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="qnaboardDetail-wrap">
          {content && (
            <table className="qnaboardDetail">
              <thead>
                <tr>
                  <td colSpan={4}>
                    <strong>{content.title}</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={3}>작성자 : {content.user.name}</td>
                  <td style={{ textAlign: "right"}}>
                    작성일 : {content.created_at.slice(2, 10)}
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{ __html: content.content }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <div className="boardBtn-wrap">
            {isLogin ? (
              <>
                {userId === boardUserId ? (
                  <>
                    <MyA to={`/qnaboard/update/${boardId}`}>
                      <button type="button" className="list">
                        수정
                      </button>
                    </MyA>
                    <button type="button" className="del" onClick={delContent}>
                      삭제
                    </button>
                  </>
                ) : (
                  <></>
                )}
                <MyA to="/qnaboard">
                  <button type="button" className="list">
                    목록
                  </button>
                </MyA>
              </>
            ) : (
              <MyA to="/qnaboard">
                <button type="button" className="list">
                  목록
                </button>
              </MyA>
            )}
          </div>
        </div>
      </ContentLayout>
    </div>
  );
}

export default QNAqnaboardDetail;
