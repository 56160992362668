import React from "react";
import { Link } from "react-router-dom";
import Subimg from "../image/subImg.jpg"
function SubImgbar() {
  return (
    <div className="menubar-container" style={{width:"100%"}}>
        <img src={Subimg} style={{width: "100%", height: "13rem"}}/>
    </div>
  );
}

export default SubImgbar;
