import axios from "axios";
import React, { useEffect, useState } from "react";
import {useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import Menulists from "../../data/Menulist";
import SubMenubar from "../layout/SubMenubar";
import SubImgbar from "../layout/SubImgbar";
import MyA from "../shared/MyA.js";
import QuillEditor from "../shared/QuillEditor.js";
import "./QNABoardInsert.css";
import { useDelData, useFormUpdateData, useGetData, useGetUserId, useUpdateData } from "../../hooks/useApi.js";
import { axiosInstance } from "../../axios.js";

function ReferenceUpdate() {
  const location = useLocation();
  const sub_menubar = Menulists.find(
    (m) => m.url == `/${location.pathname.split("/")[1]}`
  );
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );

  const boardId = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );
  const [userId, isAdmin, writer] = useGetUserId();
  const [title, content, setContent, setTitle, data] = useGetData("/board/materials", boardId )
  const [contents, setContents, delContent] = useDelData("/board/materials", boardId, "/reference")
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(1);

  const updateData = useUpdateData("/board/materials", boardId, "/reference");
  const updateFormData = useFormUpdateData("/board/materials", boardId, "/reference");

  const [file, setFile] = useState(null);

  const category = async () => {
    const { data } = await axiosInstance.get("/board/material-categories/");
    setCategoryList(data);
  };

  const handleCategoryChange = (e) =>{ setSelectedCategory(e.target.value)}
  const handleForm = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("user", parseInt(userId));
    formData.append("category", selectedCategory);
    formData.append("title", title);
    formData.append("content", content);
    formData.append("upfile", file);
    if (!file|| typeof file === "string" ) {
      formData.append("upfile", "");
    }

    // const info = {
    //   user: parseInt(userId),
    //   category: selectedCategory,
    //   title: title,
    //   username: writer,
    //   content: content,
    // };
    const processedContent = content
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "")
      .trim();
    if (title.trim() === "") {
      window.alert("제목을 입력해주세요");
      return;
    }

    if (content === "<p><br></p>" || processedContent === "") {
      window.alert("내용을 입력해주세요.");
      return;
    }

    const result = await updateFormData(formData);

    // if (!file|| typeof file === "string" ) {
    //   await updateData(info);
    // } else {
    //   await updateFormData(formData);
    // }
 }
  

  const handleText = (value) => {
    setContent(value);
  };  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    } else {
      setFile(null);
    }
  };

  useEffect(() => {
    category();
    if (data) {
      setFile(data.upfile);

    }
  }, [data]);


  return (
    <div className="boardInsert">
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="boardInsert-wrap">
          <form method="post" onSubmit={handleForm}>
            <table className="boarderInsertTable">
              <thead>
                <tr>
                  <td className="boardRow">
                    <strong>제목</strong>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="title"
                      value={title}
                      placeholder="제목을 입력해주세요"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="boardRow">
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <strong style={{ marginLeft: 10 }}>{writer}</strong>
                  </td>
                </tr>
                <tr>
                <td>
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <select                     
                      style={{ marginLeft: 10 }}
                      value={selectedCategory} 
                      onChange={handleCategoryChange}>
                      {categoryList.map((cate, key)=>{
                        return(
                        <option key={cate.id} value={cate.id}>
                          {cate.name}
                        </option>)
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="boardRow">
                    <strong>내용</strong>
                  </td>
                  <td className="QnaBoardcontent">
                    {/* <EditorToolbar/> */}
                    <QuillEditor value={content} onChange={handleText} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>파일</strong>
                  </td>
                  <td>
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileChange}
                    />
                    {file&&file.length ?(
                     <div className="row file-wrap align-items-center">
                       <div className="col-lg-10 col-md-10 col-xs-12">
                       <div className="file-name">파일: {file.substring(file.lastIndexOf("/") + 1)}</div>
                       </div>
                       <div className="col-lg-2 col-md-2 col-xs-12"><button className="file-cancle" onClick={() => setFile(null)}>파일 취소</button></div>
                      </div>
                    ):<></>}
                  </td>
                  </tr>
              </tbody>
            </table>
            <div className="QNABoardBtnGrp">
              <button type="submit">저장</button>
               <button type="button" className="del" onClick={delContent}>
                  삭제
              </button>
              <MyA to="/reference">
                <button type="button" className="list">
                  목록
                </button>
              </MyA>
            </div>
          </form>
        </div>
      </ContentLayout>
    </div>
  );
}

export default ReferenceUpdate;
