import React, { useState } from "react";
import SubMenubar from "../layout/SubImgbar";
import "./FindId.css";
import { axiosInstance } from "../../axios";
// import "../../../../scss/main.css"
const FindId = () => {
  const [findUsername, setFindUsername] = useState("");
  const [username, setUsername] = useState("");
  const [findId, setFindId] = useState("");
  const [secretNumber, setSecretNumber] = useState("");
  const [clickedTab, setClickedTab] = useState(0);
  const handleIDForm = async (e) => {
    e.preventDefault();
    await axiosInstance
      .get(
        `https://admin.miracledream.net/api/user/check-email/${findId}`
      )
      .then((res) => {
        if (res.data.valid === false) {
          window.alert("없는 아이디입니다");
        } else if (res.data.valid === true) {
          window.alert("아이디가 맞습니다");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSecretForm = async (e) => {
    e.preventDefault();
    await axiosInstance
    .post(
      `https://admin.miracledream.net/api/user/reset-password/${secretNumber}/`
    )
    .then((res) => {
      if (res.data.valid === false) {
        window.alert("없는 아이디입니다.");
      } else if (res.data.valid === true) {
        window.alert("임시비밀번호가 메일로 전송되었습니다.");
      }
      // console.log(res.data.valid);
      // if (res.data.valid === false) {
      //   window.alert("없는 아이디입니다");
      // } else if (res.data.valid === true) {
      //   window.alert("맞는 아이디입니다");
      // }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const selectMenuHandler = (index) => {
    setClickedTab(index);
  };
  return (
    <div className="findid-wrap">
      <SubMenubar />
      <div className="findIdTab-wrap">
        <div className="findIdTab">
          <div className="findIdTitle">
            <div
              className={clickedTab === 0 ? "tabtitle active-tab" : "tabtitle"}
              onClick={() => selectMenuHandler(0)}
            >
              아이디 찾기
            </div>
            <div
              className={clickedTab === 1 ? "tabtitle active-tab" : "tabtitle"}
              onClick={() => selectMenuHandler(1)}
            >
              비밀번호 찾기
            </div>
          </div>
          <div
            className={
              clickedTab === 0
                ? "findIdContent active-content"
                : "findIdContent"
            }
          >
            <form method="post" onSubmit={handleIDForm}>
              <div className="login-control">
                <input
                  type="text"
                  value={findUsername}
                  onChange={(e) => setFindUsername(e.target.value)}
                  //onChange={handleId}
                  placeholder="성명"
                  required
                />
                {/* <div className="errorMessage">
                {
                !idEmailValid && id.length>0 && (
                  <div>이메일형식으로 입력해주세요</div>
                )
                }
              </div> */}
              </div>
              <div className="login-control">
                <input
                  type="email"
                  value={findId}
                  //onChange={handlePw}
                  onChange={(e) => setFindId(e.target.value)}
                  placeholder="이메일"
                  required
                />
              </div>
              <div>
                <input type="submit" className="confirmbtn" value="아이디 찾기" />
              </div>
            </form>
          </div>
          <div
            className={
              clickedTab === 1
                ? "findIdContent active-content"
                : "findIdContent"
            }
          >
            <form method="post" onSubmit={handleSecretForm}>
              <div className="login-control">
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  //onChange={handleId}
                  placeholder="성명"
                  required
                />
                {/* <div className="errorMessage">
                  {
                  !idEmailValid && id.length>0 && (
                    <div>이메일형식으로 입력해주세요</div>
                  )
                  }
                </div> */}
              </div>

              <div className="login-control">
                <input
                  type="email"
                  value={secretNumber}
                  //onChange={handlePw}
                  onChange={(e) => setSecretNumber(e.target.value)}
                  placeholder="이메일"
                  required
                />
              </div>
              <div>
                <input type="submit" className="confirmbtn" value="비밀번호 찾기" />
              </div>
            </form>
          </div>
        </div>
        <div className="findIdfooter">
          <h5>임시비밀번호 발송메일을 받지 못했다면?</h5>
          <p>1. 스팸함을 확인해보시기 바랍니다.</p>
          <p>2. 대학교 메일은 보안문제로 수신불가할수 있습니다.</p>
        </div>
      </div>
    </div>
  );
};

export default FindId;
