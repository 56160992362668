import axios from "axios";
import React, { useState } from "react";
import "./Search.css";
const Search = ({baseurl,onChange, setNotices,setTotalCount,setNext,setPrevious}) => {
  const [searchSelect, setSearchSelect] = useState("title_only");
  const [searchKeyword, setSearchKeyword] = useState("");
  const handleSelectValue = (e) => setSearchSelect(e.target.value);
  const handleSearchValue = (e) => setSearchKeyword(e.target.value);
  async function searchContents() {
    const { data } = await axios.get(
      baseurl + `?search=${searchKeyword}&${searchSelect}=${searchSelect}`
    );
    setNotices(data.results);
    setTotalCount(data.count);
    setNext(data.next);
    setPrevious(data.previous);

    onChange(searchKeyword)
  }

  return (
    <div className="searchBox">
      <select onChange={handleSelectValue}>
        <option value="title_only">제목</option>
        <option value="content_only">내용</option>
        <option value="user_only">작성자</option>
        <option value="title_content_only">제목+내용</option>
      </select>
      <input value={searchKeyword} onChange={handleSearchValue} />
      <button type="submit" onClick={searchContents}>
        찾기
      </button>
    </div>
  );
};

export default Search;
