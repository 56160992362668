import React from 'react'
import SubImgbar from "../layout/SubImgbar"
import "./Introduce.css"
import { Link, useLocation } from 'react-router-dom'
import consultingpart from "../image/consulting2.jpg"
import educationpart from "../image/education2.png"
import ContentLayout from "../shared/ContentLayout.js"
import Menulists from '../../data/Menulist'
import SubMenubar from '../layout/SubMenubar'


const Introduce = () => {
  const location = useLocation()
  const sub_menubar = Menulists.find((m)=>m.url == location.pathname);
  const sub_menulists = Menulists.filter((ml)=>ml.mainMenu ==sub_menubar.mainMenu);

  return (
    <div className="introduce">
      <SubImgbar/>
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title}/>
      <ContentLayout>
      <div className='content'>
       <div className='row mb-5'>
          <h4  className='fs-4 fw-bold'>미라클 인공지능 연구소 소개</h4>
          <p className='fs-5 mt-1'>
            미라클 인공지능 연구소는 ‘인간을 위한 인공지능’ 이라는 핵심 가치를 통해  
          <br/>인간을 위한 인공지능을 구현하기 위한 인공지능 기술 컨설팅과 인공지능 교육을 함으로써
          <br/>인간과 인공지능이 공존하는 길을 모색합니다. </p>
        </div>
       <div className='introduceBox'>
       <div className='row mb-3'>
          <div className='col-lg-6 col-12'>
             <img src={consultingpart} className='w-100 rounded'/>
          </div>
          <div className='col-lg-6 col-12 fs-5 mt-3'>  
              <h4 className='fs-4 fw-bold'>인공지능 컨설팅</h4>
              <br/> 
              <p>
               제품 개발을 할 때 인공지능 기능 탑재는 이제 필수인 시대입니다.
               <br/>인공지능 기술을 탑재하는 것은 인간이 보다 편리하게 사용하기 위함입니다. 
               <br/>즉, 인간을 위한 인공지능 구현이 필수입니다.
               <br/>인공지능 컨설팅은 인간을 위한 기술 구현을 가능하게 도와주는 것입니다. 
              </p>
          </div>
       </div>
       <div className='row'>
         <div className='col-lg-6 col-12'>
             <img src={educationpart} className='w-100 rounded'/>
          </div>
          <div className='col-lg-6 col-12 fs-5 mt-3'>  
              <h4 className='fs-4 fw-bold'>인공지능 교육</h4>
              <br/> 
              <p>
              인공지능 교육은 인공지능과 인공지능 기술에 대해서 알고 익히는 것도 중요하지만, 인공지능과 더불어 살아가는 방법, 인공지능에 대체되지 않은 삶을 살 수 있는 방법에 대한 교육도 중요시 되어야 한다.
              <br/>인공지능 교육의 핵심은 인간이 중심이 되어야 하는 것이다 
              </p>
          </div>
         </div>
       </div>
     </div>
     </ContentLayout>
    </div>
  )
}

export default Introduce