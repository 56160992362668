import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import Menulists from "../../data/Menulist";
import SubMenubar from "../layout/SubMenubar";
import SubImgbar from "../layout/SubImgbar";
import MyA from "../shared/MyA.js";
import QuillEditor from "../shared/QuillEditor.js";
import "./QNABoardInsert.css";

import { useGetUserId, usePostData } from "../../hooks/useApi.js";

function NoticeInsert() {
  const location = useLocation();
  const sub_menubar = Menulists.find(
    (m) => m.url == `/${location.pathname.split("/")[1]}`
  );
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [username, setUsername] = useState("");
  const boardId = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );
  const [userId, isAdmin, writer] = useGetUserId();
  const [file, setFile] = useState(null);

  const postData = usePostData("/board/notices/", "/notice");

  const HandleForm = async (e) => {
    e.preventDefault();
    const processedContent = content
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "")
      .trim();
    const info={
      user: parseInt(userId),
      title:title,
      username: writer,
      content:content,
    }
    if (title.trim() === "") {
      window.alert("제목을 입력해주세요");
      return;
    }
    if (content === "<p><br></p>" || processedContent === "") {
      window.alert("내용을 입력해주세요.");
      return;
    }

      await postData(info);

  };

  const handleText = (value) => {
    setContent(value);
  };

  useEffect(() => {
    HandleForm();
  }, []);

  return (
    <div className="boardInsert">
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="boardInsert-wrap">
          <form method="post" onSubmit={HandleForm}>
            <table className="boarderInsertTable">
              <thead>
                <tr>
                  <td className="boardRow">
                    <strong>제목</strong>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="title"
                      placeholder="제목을 입력해주세요"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="boardRow">
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <strong style={{ marginLeft: 10 }}>{writer}</strong>
                  </td>
                </tr>
                <tr>
                  <td className="boardRow">
                    <strong>내용</strong>
                  </td>
                  <td className="QnaBoardcontent">
                    {/* <EditorToolbar/> */}
                    <QuillEditor value={content} onChange={handleText} />
                  </td>
                </tr>

              </tbody>
            </table>
            <div className="QNABoardBtnGrp">
              <button type="submit">저장</button>
              <MyA to="/board">
                <button type="button" className="list">
                  목록
                </button>
              </MyA>
            </div>
          </form>
        </div>
      </ContentLayout>
    </div>
  );
}

export default NoticeInsert;
