import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Menulists from "../../data/Menulist";
import SubMenubar from "../layout/SubMenubar";
import SubImgbar from "../layout/SubImgbar.js";
import ContentLayout from "../shared/ContentLayout";
import "./Map.css"
const Map = () => {
  const location = useLocation();
  const sub_menubar = Menulists.find((m) => m.url == location.pathname);
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );

  
  useEffect(() => {
    const daum = window.daum;
    new daum.roughmap.Lander({
      timestamp: "1704018328485",
      key: "2hhjs",
      mapWidth: "100%",
      mapHeight: "360",
    }).render();
  }, []);
  return (
    <>
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div
              id="daumRoughmapContainer1704018328485"
              className="root_daum_roughmap root_daum_roughmap_landing"
             style={{width: '100%', marginBottom: 20}}
            ></div>
          </div>
          <div className="col-lg-6 col-sm-12 map-left">
            <h3 style={{marginLeft: 0}}>주소</h3>
            <p style={{fontSize: 20}}>부산광역시 부산진구 동천로 109, 904호 (삼한골든게이트)</p>
            <p style={{fontSize: 16}}><span className="maptitle">- 지하철 -</span><br/>
             1호선 서면역 8번이나 10번 출구에서 직진하면 5분 거리에 위치함.</p>
             <p style={{fontSize: 16}}><span className="maptitle">- 전화번호 -</span><br/>
             010-8496-6612 </p>
             <p style={{fontSize: 16}}><span className="maptitle">- 이메일 -</span><br/>
             veron48@naver.com</p>
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default Map;
