import axios, { Axios } from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import Menulists from "../../data/Menulist";
import SubMenubar from "../layout/SubMenubar";
import SubImgbar from "../layout/SubImgbar";
import "./BoardDetail.css";
import MyA from "../shared/MyA.js";
import {axiosInstance} from "../../axios.js";
import { useRecoilValue } from "recoil";
import { isLoginSelector } from "../../recoil/User";
import ReplyList from "../shared/ReplyList.js";
import { useDelData, useGetUserId } from "../../hooks/useApi.js";
function BoardDetail() {
  const location = useLocation();
  const boardId = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );
  const sub_menubar = Menulists.find(
    (m) => m.url == `/${location.pathname.split("/")[1]}`
  );
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );

  // const [contents, setContents] = useState(null);
  const [comment, setComment] = useState("");
  const [boardUserId, setboardUserId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const [replyList, setReplyList] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const isLogin = useRecoilValue(isLoginSelector);
  
  const [userId] = useGetUserId();
  const [contents, setContents, delContent] = useDelData("/board/boards",boardId,"/board");
 
  async function getContent() {
    const { data } = await axios.get(
      `https://admin.miracledream.net/board/boards/${boardId}/`
    );
    setContents(data);
    setReplyList(data.replies);
    setboardUserId(data.user.id);
  }

  const handleCommentClick = (e) => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      window.alert("로그인이 필요합니다.");
      navigate("/login");
    }
  };

  const CommentSubmit = async (e) => {
    e.preventDefault();

    const info = {
      user: userId,
      board_post: boardId,
      content: comment,
    };
    await axiosInstance
      .post("/board/replys/", info)
      .then((res) => {
        if (res.status === 201) {
          alert("댓글이 등록되었습니다");
          window.location = `/board/detail/${boardId}`
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          alert("내용을 입력해주세요");
        }
      });
  };

  // const memoizedContents = useMemo(()=> contents,[contents])
  useEffect(() => {
    getContent();
  }, []);

 
  return (
    <div className="boardDetail">
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="boardDetail-wrap">
          {contents && (
            <>
              <table className="boardDetail">
                <thead>
                  <tr>
                    <td colSpan={4}>
                      <strong className="board-title">{contents.title}</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={3}>작성자 : {contents.user.name}</td>
                    <td style={{ textAlign: "right"}}>
                      작성일 : {contents.created_at.slice(2, 10)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      첨부파일 : {contents.upfile ? (
                        <a href={contents.upfile} style={{ marginLeft: 5 }}>
                          다운
                        </a>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <div
                        className="ql-editor"
                        dangerouslySetInnerHTML={{ __html: contents.content }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {replyList && replyList.length ? (
                <div className="reply-list">댓글목록</div>
              ) : (
                <></>
              )}
              {replyList && replyList.length ? (
                replyList.map((reply, index) => (
                  <ReplyList
                    key={index}
                    reply={reply}
                    setEditingIndex={setEditingIndex}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    editingIndex={editingIndex}
                    itemKey={index}
                    boardId={boardId}
                  />
                ))
              ) : (
                <></>
              )}

              <form
                method="post"
                style={{ margin: 0 }}
                onSubmit={CommentSubmit}
              >
                <div className="area-write">
                  <div className="box-textarea">
                    <div className="d-flex justify-content-between align-items-center">
                      {/* <label for="comment" className="screen_out">
                        댓글
                      </label>
                      <label className="d-flex align-items-center xe-label">
                        <input type="checkbox" className="secret" id="secret" checked={isSecret} onChange={handleSecretToggle} />
                        <span className="xe-label-text">비밀글</span> 
                      </label>*/}
                      <input
                      id="comment"
                      className="comment"
                      value={comment}
                      placeholder="내용을 입력해주세요."
                      onClick={handleCommentClick}
                      onChange={(e) => setComment(e.target.value)}
                    ></input>
                     <button type="submit" className="btn_register">
                      댓글쓰기
                    </button>
                    </div>
                  </div>
                  {/* <div className="box-write">
                    <div></div>
                   
                  </div> */}
                </div>
              </form>
            </>
          )}
          <div className="boardBtn-wrap">
            {isLogin ? (
              <>
                {userId === boardUserId ? 
                (<>
                  <MyA to={`/board/update/${boardId}`}>
                    <button type="button" className="list">
                      수정
                    </button>
                  </MyA>
                   <button type="button" className="del" onClick={delContent}>
                   삭제
                 </button>
                 </>
                ) : (
                  <></>
                )}
                <MyA to="/board">
                  <button type="button" className="list">
                    목록
                  </button>
                </MyA>
              </>
            ) : (
              <>
                <MyA to="/board">
                  <button type="button" className="list">
                    목록
                  </button>
                </MyA>
              </>
            )}
          </div>
        </div>
      </ContentLayout>
    </div>
  );
}

export default BoardDetail;
