import { useEffect, useState } from "react";
import { axiosInstance, axiosFormInstance } from "../axios";
import { useRecoilValue } from "recoil";
import { TokenAtom } from "../recoil/User";

export function useGetUserId() {
  const [userId, setUserId] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [writer, setWriter] = useState("");
  const accessToken = useRecoilValue(TokenAtom);

  async function getUserId() {
    if (!accessToken) return;
    await axiosInstance
      .get(
        "/api/user/check"
        // {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`
        //   }
        // }
      )
      .then((res) => {
        setUserId(res.data.id);
        setIsAdmin(res.data.isAdmin);
        setWriter(res.data.name);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setUserId(null);
        }
      });
  }
  useEffect(() => {
    getUserId();
  }, []);

  return [userId, isAdmin, writer];
}

export function useGetData(eachUrl, boardId) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [data, setData] = useState("");
  async function getData() {
    await axiosInstance.get(`${eachUrl}/${boardId}/`).then((res) => {
      setTitle(res.data.title);
      setContent(res.data.content);
      setData(res.data);
    });
  }

  useEffect(() => {
    getData();
  }, []);
  return [title, content, setContent, setTitle, data];
}

export function useAllGetData(eachUrl, boardId) {
  const [data, setData] = useState("");
  const [boardUserId, setboardUserId] = useState("");
  const accessToken = useRecoilValue(TokenAtom);

  const getData = async () => {
    if (!boardId) {
      await axiosInstance.get(`${eachUrl}/`).then((res) => {
        setData(res.data);
        if (res.data.user) {
          setboardUserId(res.data.user.id);
        } else {
          setboardUserId(null);
        }
      });
    } else {
      await axiosInstance.get(`${eachUrl}/${boardId}/`).then((res) => {
        setData(res.data);
        if (res.data.user) {
          setboardUserId(res.data.user.id);
        } else {
          setboardUserId(null);
        }
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return [data, boardUserId, getData];
}

export function usePostData(eachUrl, goToUrl) {
  const postData = async (info) => {
    await axiosInstance
      .post(eachUrl, info)
      .then((response) => {
        if (response.status === 201) {
          window.alert("등록되었습니다.");
          window.location = goToUrl;
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          window.alert("실패했습니다.");
        }else{
          window.alert("용량이 큽니다. 더 작은 용량을 넣어주시길바랍니다.")
          console.log(error);
        }
      });
  };
  return postData;
}
export function useFormPostData(eachUrl, goToUrl) {
  const postFormData = async (info) => {
    await axiosFormInstance
      .post(eachUrl, info)
      .then((response) => {
        if (response.status === 201) {
          window.alert("등록되었습니다.");
          window.location = goToUrl;
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          window.alert("실패했습니다.");
        } else {
          console.log(error);
        }
      });
  };
  return postFormData;
}

export function useUpdateData(eachUrl, boardId, goToUrl) {
  const updateData = async (info) => {
    await axiosInstance
      .put(`${eachUrl}/${boardId}/`, info)
      .then((response) => {
        if (response.status === 200) {
          window.alert("등록되었습니다.");
          window.location = goToUrl;
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          window.alert("실패했습니다.");
        } else {
          console.log(error);
        }
      });
  };
  return updateData;
}

export function useFormUpdateData(eachUrl, boardId, goToUrl) {
  const updateFormData = async (info) => {
    await axiosFormInstance
      .put(`${eachUrl}/${boardId}/`, info)
      .then((response) => {
        if (response.status === 200) {
          window.alert("등록되었습니다.");
          window.location = goToUrl;
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          window.alert("게시글 등록에 실패했습니다.");
        } else {
          console.log(error);
        }
      });
  };
  return updateFormData;
}

export function useDelData(eachUrl, boardId, goToUrl) {
  const [contents, setContents] = useState(null);

  async function delContent() {
    const { data } = await axiosInstance
      .delete(`${eachUrl}/${boardId}/`)
      .then((response) => {
        if (response.status === 204) {
          window.alert("삭제되었습니다.");
          window.location = goToUrl;
        }
        setContents(data);
      })
      .catch((e) => {
        if (e.response.status === 403) {
          window.alert("삭제할 수 없습니다.");
        } else if (e.response.status === 401) {
          window.alert("로그인이 필요합니다.");
          window.location = "/login";
        }
        // 알럿, 리다이렉팅
      });
  }
  return [contents, setContents, delContent];
}
