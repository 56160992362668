import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import Menulists from "../../data/Menulist";
import SubMenubar from "../layout/SubMenubar";
import SubImgbar from "../layout/SubImgbar";
import MyA from "../shared/MyA.js";
import QuillEditor from "../shared/QuillEditor.js";
import "./QNABoardInsert.css";
import {
  useDelData,
  useUpdateData,
  useGetData,
  useGetUserId
} from "../../hooks/useApi.js";

function GalleryUpdate() {
  const location = useLocation();
  const sub_menubar = Menulists.find(
    (m) => m.url == `/${location.pathname.split("/")[1]}`
  );
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );

  const boardId = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );
  const [userId, isAdmin, writer] = useGetUserId();
  const [title, content, setContent, setTitle, data] = useGetData("/board/gallery-posts", boardId);
  const [contents, setContents, delContent] = useDelData("/board/gallery-posts",boardId,"/gallery");
  const  updateData = useUpdateData("/board/gallery-posts", boardId, "/gallery");
 
  const handleForm = async (e) => {
    e.preventDefault();
    const info ={
      user: parseInt(userId),
      title: title,
      content: content
    }
    const processedContent = content
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "")
      .trim();
    if (content === "<p><br></p>" || processedContent === "") {
      window.alert("내용을 입력해주세요.");
      return;
    }
    await updateData(info);
  };

  const handleText = (value) => {
    setContent(value);
  };
  

  return (
    <div className="boardInsert">
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="boardInsert-wrap">
          <form method="post" onSubmit={handleForm}>
            <table className="boarderInsertTable">
              <thead>
                <tr>
                  <td>
                    <strong>제목</strong>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="title"
                      value={title}
                      placeholder="제목을 입력해주세요"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <strong style={{ marginLeft: 10 }}>{writer}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>내용</strong>
                  </td>
                  <td className="QnaBoardcontent">
                    {/* <EditorToolbar/> */}
                    <QuillEditor value={content} onChange={handleText} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="QNABoardBtnGrp">
              <button type="submit">저장</button>
              <button type="button" className="del" onClick={delContent}>
                삭제
              </button>
              <MyA to="/board">
                <button type="button" className="list">
                  목록
                </button>
              </MyA>
            </div>
          </form>
        </div>
      </ContentLayout>
    </div>
  );
}

export default GalleryUpdate;
