import { Navigate, Outlet, useLocation } from "react-router";
import {  useRecoilValue, } from "recoil";
import {  isLoginSelector } from "../recoil/User"


const ProtectedRoute = () => {
  const isLogin = useRecoilValue(isLoginSelector);
  const currentLocation = useLocation();


  return isLogin ? (
    <Outlet />
  ) : (
    <Navigate
      to={"/login"}
      replace
    />
  );
};

export default ProtectedRoute;