import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js"
import Menulists from '../../data/Menulist.js'
import SubMenubar from '../layout/SubMenubar.js'
import SubImgbar from "../layout/SubImgbar.js"
import "./Board.css";
import MyA from "../shared/MyA.js";
import {FaPaperclip} from 'react-icons/fa';
import Pagination from "../shared/Pagination.js";
import Search from "../shared/Search.js";
import useResize from "../../hooks/useResize.js";
import { useGetUserId } from "../../hooks/useApi.js";

function Notice() {
  const location = useLocation();
  const base_url = "https://admin.miracledream.net/board/notices";
  const sub_menubar = Menulists.find((m)=>m.url == location.pathname);
  const sub_menulists = Menulists.filter((ml)=>ml.mainMenu ==sub_menubar.mainMenu);
  
  const [notices, setNotices] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [baseurl, setbaseurl] = useState(base_url);
  const [currentpage, setcurrentpage] = useState(1);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [loading, setLoading] = useState(true);
  const perpage = 20;
  const [userId, isAdmin, writer] = useGetUserId();
  const windowWidth = useResize();

  function changeurl(event) {
    setbaseurl(event);
  }
  async function getNotices() {
    setLoading(true);
    const { data } = await axios.get(baseurl);
    setNotices(data.results);
    setTotalCount(data.count);
    setNext(data.next);
    setPrevious(data.previous);
    setLoading(false);
  }

  useEffect(() => {
    getNotices(baseurl);
  }, [baseurl]);


  return (
    <div className="board-container">
    <SubImgbar />
    <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
    <ContentLayout>
      <div className="board-wrap">
      {isAdmin ? (
            <div className="boardwrite">
              <MyA to="/notice/insert">
                <button className="boardwrite-btn" type="button">
                  글쓰기
                </button>
              </MyA>
            </div>
          ) : (
            <></>
          )}
        {windowWidth > 768 ? (
          <>
            <table className="board">
              <thead>
                <tr>
                  <td>
                    <strong>번호</strong>
                  </td>
                  <td>
                    <strong>제목</strong>
                  </td>
                  <td>
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <strong>파일</strong>
                  </td>
                  <td>
                    <strong>등록일</strong>
                  </td>
                </tr>
              </thead>

              <tbody>
                {loading ? (
                    <tr>
                  <td colSpan={5} >Loading...</td>
                  </tr>
                ) : (
                  <>
                    {notices && notices.length ? (
                      notices.map((notice, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              {totalcount - perpage * (currentpage - 1) - key}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <MyA to={`detail/${notice.id}`}>
                                  {notice.title}
                              </MyA>
                            </td>
                            <td>admin</td>
                            <td>
                              {notice.upfile ? (
                                <span>
                                  <FaPaperclip />
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>{notice.created_at.slice(2, 10)}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5}>등록된 글이 없습니다</td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
            <div className="boardBottom">
              <Pagination
                notices={notices}
                previous={previous}
                base_url={base_url}
                changeurl={changeurl}
                setcurrentpage={setcurrentpage}
                currentpage={currentpage}
                totalcount={totalcount}
                perpage={perpage}
                next={next}
              />
                <Search
                baseurl={baseurl}
                setNotices={setNotices}
                setTotalCount={setTotalCount}
                setNext={setNext}
                setPrevious={setPrevious}
                />
            </div>
          </>
        ) : (
          <>
            <table className="board">
              <thead>
                <tr>
                  <td>
                    <strong>번호</strong>
                  </td>
                  <td>
                    <strong>제목</strong>
                  </td>
                  <td>
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <strong>등록일</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                {notices && notices.length>0 ? (
                  notices.map((notice, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {totalcount - perpage * (currentpage - 1) - key}
                        </td>
                        <td style={{ textAlign: "left" }}>
                              <MyA to={`detail/${notice.id}`}>
                                  {notice.title}
                              </MyA>
                            </td>
                        <td>admin</td>
                        <td>{notice.created_at.slice(5, 10)}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5}>등록된 글이 없습니다</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="boardBottom">
              <div className="d-colum w-100">
                <div className="bottompagination">
                  <Pagination
                    notices={notices}
                    previous={previous}
                    base_url={base_url}
                    changeurl={changeurl}
                    setcurrentpage={setcurrentpage}
                    currentpage={currentpage}
                    totalcount={totalcount}
                    perpage={perpage}
                    next={next}
                  />
                </div>
                <div className="searchSBox">
                  <Search
                    baseurl={baseurl}
                    setNotices={setNotices}
                    setTotalCount={setTotalCount}
                    setNext={setNext}
                    setPrevious={setPrevious}
                    />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </ContentLayout>
  </div>
);
}

export default Notice;
