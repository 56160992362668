import React from 'react'
import ContentLayout from "../shared/ContentLayout.js"
import Menulists from '../../data/Menulist'
import SubMenubar from '../layout/SubMenubar'
import SubImgbar from "../layout/SubImgbar"
import alanturing from "../image/ai/alanturing.jpg"
import colossus from "../image/ai/colossus.jpg"
import edvac from "../image/ai/edvac1.jpg"
import leesaedol from "../image/ai/leesaedol.png"
import deepblue from "../image/ai/deepblue.png"
import aiwhatson from "../image/ai/aiwhatson.png"
import { useLocation } from 'react-router-dom'
import "./Ai.css"
const Ai = () => {
 const location = useLocation();
 const sub_menubar = Menulists.find((m)=>m.url == location.pathname);
 const sub_menulists = Menulists.filter((ml)=>ml.mainMenu ==sub_menubar.mainMenu);
  return (
    <div>
    <SubImgbar/> 
     <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
     <ContentLayout>
      <div className='aiContent fs-5'>
        <h4 className='fs-4 fw-bold'>인공지능 정의</h4>
        <p>- 인공지능(Artificial Intelligence)이라는 용어를 사용한 것은 1956년 다트머스 대학교 하계워크숍에서다.
        <br/> - 1956년 다트머스 대학교 하계 워크숍에서 ‘인간처럼 생각하고 사고하고 행동하는 기계’를 ‘인공지능’이라고 정의를 했다.
        </p>
        <br/>
        <h4 className='fs-4 fw-bold'>인공지능의 역사</h4>
        <p>- 인공지능의 역사는 컴퓨터의 역사다</p>
       <div className='aiHistory fs-6'>
         <div className='row'>
          <div className='col-2 col-md-1 order-md-2 text-md-center fw-bold year'>2022년</div>
          <div className='col-10 col-md-6 order-md-3'>ChatGPT상용화</div>
          <div className='col-md-5 order-md-1'></div>      
         </div>
         <div className='row'>
          <div className='col-2 col-md-1 order-md-2 text-md-center fw-bold year'>2016년</div>
          <div className='col-3 col-md-1 order-md-3'><img src={leesaedol} style={{width: "6rem"}}/></div>
          <div className='col-7 col-md-5 order-md-4'>인공지능 알파고 이세돌 9단과 바둑대국에서 4승 1패로 승리</div>
          <div className='col-md-5 order-md-1'></div>        
         </div>
         <div className='row mt-1'>
          <div className='col-2 col-md-1 order-md-3 text-md-center fw-bold year'>2011년</div>
          <div className='col-3 col-md-1 order-md-2'><img src={aiwhatson} style={{width: "6rem"}}/></div> 
          <div className='col-7 col-md-4 order-md-1 text-md-end'>인공지능 왓슨 퀴즈쇼 제퍼디에서 우승</div>  
          <div className='col-md-5 order-md-4'></div>
         </div>
         <div className='row'>
          <div className='col-2 col-md-1 order-md-2 text-md-center fw-bold year'>1997년</div>
          <div className='col-10 col-md-6 order-md-3'>인간과의 체스대결에서 AI가 최초로 승리(딥 블루)</div>
          <div className='col-md-5 order-md-1'></div>
         </div>
         <div className='row'>
          <div className='col-2 col-md-1 order-md-2 text-md-center fw-bold year'>1996년</div>
          <div className='col-3 col-md-1 order-md-3'><img src={deepblue} style={{width: "6rem"}}/></div>
          <div className='col-7 col-md-5 order-md-4'>AI체스프로그램 ‘딥 블루’ 개발</div>
          <div className='col-md-5 order-md-1'></div>        
         </div>
         <div className='row'>
          <div className='col-2 col-md-1 order-md-2 text-md-center fw-bold year'>1953년</div>
          <div className='col-10 col-md-6 order-md-3'>앨런 튜링이 체스를 두는 인공지능에 대해서 언급
           <br/>- 머신러닝과 딥러닝의 개념을 언급</div>
          <div className='col-md-5 order-md-1'></div>
         </div>
         <div className='row'>
          <div className='col-2 col-md-1 order-md-3 text-md-center fw-bold year'>1952년</div>
          <div className='col-3 col-md-1 order-md-2'><img src={edvac} style={{width: "6rem"}}/></div>   
          <div className='col-7 col-md-4 order-md-1 text-md-end'>폰 노이만이 최초의 2진법 체계를 사용한 컴퓨터 
          <br/>에드박(EDVAC)개발
          <br/>- 현대 컴퓨터의 원조가 에드박이다
          <br/>- 현대 컴퓨터와 에드박인 구동방식이 똑같다</div>
          <div className='col-md-5 order-md-4'></div>
         </div>
         <div className='row'>
          <div className='col-2 col-md-1 order-md-2 text-md-center fw-bold year'>1950년</div>
          <div className='col-10 col-md-6 order-md-3'>앨런 튜링 계산 기계와 지능(Computing Machinery and Intelligence) 이라는 논문에서 ChatGPT의 기초가 되는 흉내게임(Imitation Game)에 대해 언급</div>
          <div className='col-md-5 order-md-1'></div>
         </div>
         <div className='row'>
          <div className='col-2 col-md-1 order-md-3 text-md-center fw-bold year'>1948년</div>
          <div className='col-3 col-md-1 order-md-2'><img src={alanturing} style={{width: "6rem"}}/></div> 
          <div className='col-7 col-md-4 order-md-1 text-md-end'>앨런 튜링 ‘지능을 가진 기계(Intelligent Machinery)라는 논물을 발표
           <br/> - 최초로 인공지능을 언급</div>  
          <div className='col-md-5 order-md-4'></div>
         </div>
         <div className='row'>
         <div className='col-2 col-md-1 order-md-2 text-md-center fw-bold year'>1943년</div>
          <div className='col-3 col-md-1 order-md-3'><img src={colossus} style={{width: "6rem"}}/></div>
          <div className='col-7 col-md-5 order-md-4'>인공지능 알파고 이세돌 9단과 바둑대국에서 4승 1패로 승리</div>
          <div className='col-md-5 order-md-1'></div>       
         </div>
       </div>
      </div>
    </ContentLayout>
      
    </div>
  )
}

export default Ai