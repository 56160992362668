import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Miraclelogo from "../image/Miraclelogo.png";
import "./Navbar.css";
import MyA from "../shared/MyA";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { MdKeyboardArrowDown } from "react-icons";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { RedirectAtom, isLoginSelector } from "../../recoil/User";
import { FiLogOut } from "react-icons/fi";
import { axiosInstance } from "../../axios";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function Navbar() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const navigate = useNavigate();
  const isLogin = useRecoilValue(isLoginSelector);
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location = "/";
  };

  const WA = (props) => {
    return (
      <Link
        {...props}
        style={{
          color: "white",
          textDecoration: "none",
          fontSize: "15px",
        }}
        onClick={() => {
          setIsHamOn(!isHamOn);
        }}
      ></Link>
    );
  };
  const MyWA = (props) => {
    return (
      <Link
        {...props}
        style={{
          color: "white",
          textDecoration: "none",
        }}
      ></Link>
    );
  };
  const [isHamOn, setIsHamOn] = useState(false);
  const setPathname = useSetRecoilState(RedirectAtom);
  const location = useLocation();
  const loginHandler = () => {
    setPathname(location.pathname);
    navigate("/login");
  };
  useEffect(() => {
    // (async () => {
    //   const response = await axiosInstance.post('/api/user/refresh/',{
    //     refresh: localStorage.getItem("refresh_token")
    //   })
    //   localStorage.setItem('access_token', response.data.access);
    // })();

    // /api/user/verify/
    const token = localStorage.getItem('access_token');
    if (token) {
      axiosInstance.post("https://admin.miracledream.net/api/user/verify/", {
        token: token
      }).then((response) => {
        if (response.status === 200) {
          // navigate("/");
          // alert("이미 로그인 되어있습니다.");
        }
      }).catch((error) => {
        console.log(error);
        alert("만료되어 로그아웃 되었습니다. 다시 로그인 해주십시오.");
        handleLogout();
      })
    }
  }, []);
  return (
    <>
      <div className="nav-container">
        <div className="nav-wrap">
          <div className="nav">
            <div className="nav-left">
              <Link to="/">
                <img src={Miraclelogo} />
              </Link>
            </div>
            {windowSize.innerWidth > 1200 ? (
              <div className="nav-right">
                {isLogin ? (
                  <div className="nav-loginlist">
                    <MyWA to="/mypage">마이페이지</MyWA>
                    <span onClick={handleLogout}>로그아웃</span>
                  </div>
                ) : (
                  <div className="nav-loginlist">
                    <span onClick={loginHandler}>로그인</span>
                    <MyWA to="/registerconfirm">회원가입</MyWA>

                    {/* onClick={()=> {setUser("null")
            navigate("/")}} */}
                  </div>
                )}

                <div className="nav-menu">
                  <ul>
                    <li className="dropdown">
                      <MyA to="/introduce">연구소 소개</MyA>
                      <div className="dropdown-content">
                        <MyA to="/introduce">연구소 소개</MyA>
                        <MyA to="/introduce/ceo">대표소개</MyA>
                        <MyA to="/location">오시는길</MyA>
                      </div>
                    </li>
                    <li className="dropdown">
                      <MyA to="/ai">인공지능</MyA>
                      <div className="dropdown-content">
                        <MyA to="/ai">인공지능</MyA>
                        <MyA to="/trend">트랜드</MyA>
                      </div>
                    </li>
                    <li className="dropdown">
                      <MyA to="/notice">연구소 소식</MyA>
                      <div className="dropdown-content">
                        <MyA to="/notice">공지사항 및 소개</MyA>
                        <MyA to="/gallery">갤러리</MyA>
                      </div>
                    </li>
                    <li className="dropdown">
                      <MyA to="/board">게시판</MyA>
                      <div className="dropdown-content">
                        <MyA to="/board">게시판</MyA>
                        <MyA to="/reference">자료실</MyA>
                        <MyA to="/fnq">FAQ</MyA>
                        <MyA to="/qnaboard">Q&A</MyA>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div>
                <div
                  onClick={() => {
                    setIsHamOn(!isHamOn);
                  }}
                >
                  <FontAwesomeIcon icon={faBars} className="fabar" size="3x" />
                </div>
                {isHamOn ? (
                  <div className="hamberMenu-wrap">
                    <div className="hamberMenu">
                      <ul className="navHamberMenu">
                        <li>
                          <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="0" className="headitem">
                              <MyWA
                                to="/"
                                onClick={() => {
                                  setIsHamOn(!isHamOn);
                                }}
                              >
                                HOME
                              </MyWA>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" className="headitem">
                              <Accordion.Header>
                                <MyWA
                                  to="/introduce"
                                  onClick={() => {
                                    setIsHamOn(!isHamOn);
                                  }}
                                >
                                  연구소 소개
                                </MyWA>
                              </Accordion.Header>
                              <Accordion.Body className="accodionBody">
                                <WA to="/introduce">연구소 소개</WA>
                                <br />
                                <WA to="/introduce/ceo">대표소개</WA>
                                <br />
                                <WA to="/location">오시는길</WA>
                                <br />
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2" className="headitem">
                              <Accordion.Header>
                                <MyWA
                                  to="/ai"
                                  onClick={() => {
                                    setIsHamOn(!isHamOn);
                                  }}
                                >
                                  인공지능
                                </MyWA>
                              </Accordion.Header>
                              <Accordion.Body className="accodionBody">
                                <WA to="/ai">인공지능</WA>
                                <br />
                                <WA to="/trend">트랜드</WA>
                                <br />
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3" className="headitem">
                              <Accordion.Header>
                                <MyWA
                                  to="/notice"
                                  onClick={() => {
                                    setIsHamOn(!isHamOn);
                                  }}
                                >
                                  연구소 소식
                                </MyWA>
                              </Accordion.Header>
                              <Accordion.Body className="accodionBody">
                                <WA to="/notice">공지사항 및 소개</WA>
                                <br />
                                <WA to="/gallery">갤러리</WA>
                                <br />
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4" className="headitem">
                              <Accordion.Header>
                                <MyWA
                                  to="/board"
                                  onClick={() => {
                                    setIsHamOn(!isHamOn);
                                  }}
                                >
                                  게시판
                                </MyWA>
                              </Accordion.Header>
                              <Accordion.Body className="accodionBody">
                                <WA to="/board">게시판</WA>
                                <br />
                                <WA to="/reference">자료실</WA>
                                <br />
                                <WA to="/fnq">FAQ</WA>
                                <br />
                                <WA to="/qnaboard">Q&A</WA>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </li>
                        {isLogin ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginTop: 7,
                            }}
                          >
                            <li>
                              <MyWA
                                to="/mypage"
                                onClick={() => {
                                  setIsHamOn(!isHamOn);
                                }}
                              >
                                MYPAGE
                              </MyWA>
                            </li>
                            <li>
                              <MyWA
                                onClick={() => {
                                  handleLogout();
                                  // setIsHamOn(!isHamOn);
                                }}
                              >
                                <FiLogOut />
                              </MyWA>
                            </li>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginTop: 7,
                            }}
                          >
                            <li>
                              <span
                                onClick={() => {
                                  loginHandler();
                                  setIsHamOn(!isHamOn);
                                }}
                                className="login-span"
                              >
                                LOGIN
                              </span>
                            </li>
                            <li>
                              <MyWA
                                to="/registerconfirm"
                                onClick={() => {
                                  setIsHamOn(!isHamOn);
                                }}
                              >
                                JOIN
                              </MyWA>
                            </li>
                          </div>
                        )}
                      </ul>
                      <span
                        onClick={() => {
                          setIsHamOn(!isHamOn);
                        }}
                      >
                        <FontAwesomeIcon icon={faX} className="faX" />
                      </span>
                    </div>
                    <div></div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
      <section>
        <Outlet />
        <Footer />
      </section>
    </>
  );
}

export default Navbar;
