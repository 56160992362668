import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import SubImgbar from "../layout/SubImgbar"
import "./CeoIntroduce.css"
import ceo from "../image/ceo.jpg"
import book from "../image/book.png"
import ContentLayout from "../shared/ContentLayout.js"
import Menulists from '../../data/Menulist'
import SubMenubar from '../layout/SubMenubar'

const CeoIntroduce = () => {
  const location = useLocation();
  const sub_menubar = Menulists.find((m)=>m.url == location.pathname);
  const sub_menulists = Menulists.filter((ml)=>ml.mainMenu ==sub_menubar.mainMenu);
 
  return (
    <div className='ceoIntroduce-container'>
     <SubImgbar/> 
     <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
     <ContentLayout>   
      <div className='content'>
       <div className='row gap-4'>
          <div className='col-lg-4 col-xs-10 '>
             <img src={ceo} style={{width: '100%'}}/>
          </div>
          <div className='col-lg-7 col-xs-10 fs-5 mt-1'>  
              <h4 className='fs-4 fw-bold'>미라클 인공지능연구소 대표 인사말</h4>
              <br/> 
              <p>
              인공지능의 발전으로 인해 많은 것이 변화하고 있습니다.
              <br/>알파고 이후 인공지능은 우리 생활 속으로 들어왔습니다.
              <br/>이런 시대적 상황을 거스를 수 없다는 것은 지나온 역사를 통해 알 수 있습니다.
              <br/>인공지능은 이제 대세입니다. 
              <br/>모든 제품에 인공지능 기술이 들어갈 것이고, 이것은 반드시 인간을 위한 것이어야 됩니다.
              <br/><br/>미라클 인공지능연구소는 ‘인간을 위한 인공지능’을 핵심가치로 삼고,
              <br/> 인간을 위한 인공지능 컨설팅, 인간을 위한 인공지능 교육을 위해 노력하고록 하겠습니다.  
              </p>
              <br/>
              <p className='fs-5 fw-bold text-end'>미라클 인공지능연구소 대표 차석호</p>
          </div>
       </div>
       <div className='row'>
         <div className='bookwrap text-center'>
          <img src={book} className='bookImg'/>
          </div>
       </div>
       </div>
       </ContentLayout>
    </div>
  )
}

export default CeoIntroduce