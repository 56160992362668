import axios from "axios";
// import {axiosInstance} from "../../../axios";
import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Register.css";
import SubImgbar from "../layout/SubImgbar"
import { usePostData } from "../../hooks/useApi";

const Register = () => {
  const [id, setId] = useState("");
  const [passwd, setPasswd] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  //const [passwdError, setPasswdError] = useState(false);
  const [repasswd, setRepasswd] = useState("");
  const [isAllChecked, setIsAllChecked] = useState(false);
  //유효성 검사
  const [idEmailValid, setIdEmailValid] = useState(false);
  const [pwValid, setPwValid] = useState(false)
  const [EmailValid, setEmailValid] = useState(false);
  const [checkagreeOne, setCheckagreeOne] = useState("");
  const navigate = useNavigate();
  const postData = usePostData("/api/user/register/","/login" );
  async function HandleForm(e) {
    e.preventDefault();
    if(!checkagreeOne){
      alert('이메일수신동의여부를 선택해주세요')
      return
    }
    const info ={
      email: email,
      name: username,
      password: passwd,
      phone_num: phone,
      isEmailCheck: checkagreeOne,
    };
  // console.log(info)

  //  postData(info)
    // {axiosInstance}
    //   .post(`user/register/`, info)
    //   .then((response) => {

    await axios.post('https://admin.miracledream.net/api/user/register/', info)
     .then((response)=>{
       if(response.status === 200){
        window.alert("가입하셨습니다")
        navigate('/login');
       }
        // localStorage.setItem("token", response.data.token);
        // localStorage.setItem("id", response.data.id);
        // navigate("/login");
      }).catch((error)=>{
        if(error.response.status===400){
          window.alert("사용자가 이미 존재합니다")
        }
      })
      
      
        // if (passwdError === true) {
        //   window.alert("비밀번호 일치여부를 확인해 주세요");
        // } else {
        //   if (response.data.status === 201) {
        //     window.alert(response.data.message);
       
        //   } else {
        //     window.alert("로그인에 실패하였습니다");
        //   }
        // }
     
    
  }

  // if (passwd === passwd2) {
    //   return setPasswdError(false);
    // } else if (passwd !== passwd2) {
    //   return setPasswdError(true);
    // } else {
    //   return setPasswdError(true);
    // }

  // const onChangePasswd = (e) => {
  //   setPasswd(e.target.value);
  // };

  // const onChangePasswdCheck = (e) => {
  //   setPasswdError(e.target.value !== passwd);
  //   // e.target.value = 비밀번호 확인란에 적힌 비번
  //   // passwd는 기존에 적은 비번
  //   // 입력할때마다 passwd 검증하는 함수다.. 다른거니까 false겟죠??
  //   setPasswd2(e.target.value);
    
  // };

  const MyA = (props) => {
    return (
      <Link {...props} style={{
        color: "rgb(87, 87, 87)", textDecoration:"none", float: "right", fontSize: "14px"
      }}></Link>
    )
  }

  const handleId = (e) =>{
    setId(e.target.value.trim());
    const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if(regex.test(id)){
      setIdEmailValid(true);
    }else{
      setIdEmailValid(false);
    }
  }

  const handlePw = (e) =>{
    setPasswd(e.target.value);
    const regex =  /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{7,20}$/;
    const test = regex.test(passwd.trim())
    if(test){
      setPwValid(true)
    }else{
      setPwValid(false);
    }
  }

  const handleEmail= (e) =>{
    setEmail(e.target.value);
    const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if(regex.test(email.trim())){
      setEmailValid(true);
    }else{
      setEmailValid(false);
    }

  }
  const checkOnlyOne = (chk) => {
    var obj = document.getElementsByName("agreechk");
    for(var i=0; i<obj.length; i++){
    if(obj[i] != chk){
    obj[i].checked = false;
    }
    }
  }

   

  return (
    <div className="signInWrap">
        <SubImgbar/>
     <div className="signIn-box">
       <div className="signIn-container">
        <p className="signHead">회원가입</p>
           <form method="post" onSubmit={HandleForm} autoComplete="off"> 
           <input
              type="text"
              name="email"
              value={email}
              placeholder="아이디(이메일을 적어주세요)"
              onChange={handleEmail}
              required
            />
            <div className="register-id-eplain">
             <span>비번을 찾거나 변경 시, 아이디로 입력한 이메일을 통해 확인합니다.</span>  
            </div> 
             <div className="errorMessage">
                {
                  (!EmailValid && email.length>0) && (
                    <div>이메일형식으로 입력해주세요</div>
                  )
                }
              </div>
            <input 
              type="password" 
              name="passwd" 
              value={passwd}
              // onChange={(e)=> setPasswd(e.target.value)}
              onChange={handlePw}
              required
              placeholder="비밀번호"/>
              <div className="errorMessage">
                {
                  !pwValid && passwd.length>0 && (
                    <div>영문,숫자,특수문자 포함 8자이상 20자이하 입력해주세요</div>
                  )
                }
              </div>
            <input
              type="password"
              name="repasswd"
              placeholder="비밀번호확인"
              value={repasswd}
              required
              onChange={(e)=> setRepasswd(e.target.value)}
            />
            <div className="errorMessage">
                {
                   passwd !==repasswd && repasswd.length>0 && (<div>비밀번호가 일치하지않습니다</div>
                  )
                }
              </div>
            <input
              type="text"
              name="username"
              placeholder="사용자이름"
              required
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="text"
              name="phone"
              placeholder="전화번호(숫자만)"
              required
              onChange={(e) => setPhone(e.target.value)}
            />
            
              <div className='emailAgreebox-wrap'>
              <label>이메일수신여부</label> 
              <div className='emailAgreeChekbox'>
               <input type="checkbox" name="agreechk" value="true" onChange={(e)=> setCheckagreeOne(e.target.value)} onClick={(e)=>checkOnlyOne(e.target)}/><span>수신함</span>
               <input type="checkbox" name="agreechk" value="false" onChange={(e)=> setCheckagreeOne(e.target.value)} onClick={(e)=>checkOnlyOne(e.target)}/><span>수신안함</span>
               </div>
            </div> 
              
            <button className="registerBtn"> 가입 </button>
            <MyA to="/login">
              <strong>계정이 있으신가요?</strong>
            </MyA>
          
          </form>
        </div>
       </div>
    </div>
  );
};

export default Register;
