import axios from "axios";
import React, { Component, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import main from "../image/main.jpg";
import main2 from "../image/main6.jpg";
import main3 from "../image/main3.jpg";
import "./Main.css";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import NoticeTab from "./tabs/NoticeTab";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gallery4 from "../image/gallery4.jpg";
import brandtitle from "../image/brandlogomain.png";
import coexistenceicon from "../image/coexistsection.png";
import consultingicon from "../image/consultingsection.png";
import educationicon from "../image/educationsection.png";
import maintitle from "../image/maintitle.png";
import subtitle from "../image/subtitle.png";
import brandcontent from "../image/brandcontent.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import mainMobile from "../image/mainMobile.jpg";
import mainMobile2 from "../image/mainMobile2.jpg";
import mainMobile3 from "../image/mainMobile3.jpg";
import Card from "react-bootstrap/Card";

import QnaNoticeTab from "./tabs/QnaNoticeTab";
import GallerySlide from "../shared/GallerySlide";
// import { useCallback, useRef } from "react";




function Main() {
  const mainSlidSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  const slideImages = [main, main2, main3];
  const slideMobileImages = [mainMobile, mainMobile2, mainMobile3];

  var gallerySettings = {
    dots: true,
    infinite: true,
    // speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div className="main-container">
      <div className="slide">
        {windowSize.innerWidth > 550 ? (
          <Slider {...mainSlidSettings} dotsClass="test-css">
            {slideImages.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundImage: `url(${slideImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top center",
                    backgroundSize: "cover",
                    height: "47rem",
                  }}
                >
                  <ul className="rel">
                    <li>
                      <img
                        className="brandTitle1"
                        src={maintitle}
                        alt="인간과 인공지능의 공존"
                      />
                    </li>
                    <li>
                      <img
                        className="brandTitle2"
                        src={subtitle}
                        alt="4차 산업혁명시대"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <>
            <Slider {...mainSlidSettings} dotsClass="test-css">
              {slideMobileImages.map((slideImage, index) => (
                <div key={index}>
                  <div
                    style={{
                      backgroundImage: `url(${slideImage})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: "35rem",
                    }}
                  >
                    <ul className="mobileRel">
                      <li className="mobileMainTitle1">인간과 </li>
                      <li className="mobileMainTitle2">인공지능의 공존 </li>
                      <li className="mobileMainSubTitle">
                        미라클 인공지능연구소가 만들어갑니다
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </Slider>
          </>
        )}
      </div>
      <div className="brand">
        <div className="brand-container">
          <div className="brand-title">
            <img className="brand-title-img" src={brandtitle} />
            <br />
            {/* <p>'인간을 위한 인공지능'이라는 핵심가치를 통해</p>
            <p>인공지능 기술 컨설팅과 인공지능 교육을 통해</p>
            <p>인간과 인공지능이 공전하는 길을 모색합니다</p> */}
            <img className="brand-content-img" src={brandcontent} />
          </div>
          <div className="row mt-4 d-flex justify-content-evenly">
            <div className="col-4">
              <Card className="border-0">
                <Card.Img variant="top" src={educationicon} className="card-img"/>
              </Card>
            </div>
            <div className="col-4">
              <Card className="border-0">              
                <Card.Img variant="top" src={coexistenceicon} className="card-img"/>
              </Card>
            </div>
            <div className="col-4">
              <Card className="border-0">
                <Card.Img variant="top" src={consultingicon} className="card-img"/>
              </Card>
            </div>
          </div>
        </div>
      </div>
      {/* {windowSize.innerWidth > 500 ? 
          <div className="brand-content-wrap">
            <div className="brand-content">
              <img src={education}/>
            </div> 
            <div className="brand-content" >
            <img src={coexistence}/>
            </div> 
            <div className="brand-content">
            <img src={consulting}/>
            </div> 
          </div>
        :<>
            <div className="brand-content-wrap-moblie">
            <div className="brand-content">
              <img src={education}/>
            </div> 
            <div className="brand-content" >
            <img src={coexistence}/>
            </div> 
            <div className="brand-content">
            <img src={consulting}/>
            </div> 
          </div>
         </>}*/}

      <div className="notice-container">
        <div className="row g-5 mx-1 d-flex flex-direction justify-content-center notice">
          <div className="col-lg-5 col-md-5 col-12">
            <Tabs
              defaultActiveKey="공지사항"
              transition={false}
              id="noanim-tab-example"
              justify
            >
              <Tab eventKey="공지사항" title="공지사항">
              <NoticeTab path="notices" detailPath="notice"/>              </Tab>
              <Tab eventKey="자료실" title="자료실">
               <NoticeTab path="materials" detailPath="reference"/>
              </Tab>
              {/* <Tab eventKey="알림" title="알림">
                <p>Tab 2 works!</p>
              </Tab> */}
            </Tabs>
          </div>
          <div className="col-lg-5 col-md-5 col-12">
            <Tabs
              defaultActiveKey="게시판"
              transition={false}
              id="noanim-tab-example"
              justify
            >
              <Tab eventKey="게시판" title="게시판">
              <NoticeTab path="boards" detailPath="board"/>
           
              </Tab>
              {/* <Tab eventKey="Q&A" title="Q&A">
                <p>Tab 2 works!</p>
              </Tab> */}
              <Tab eventKey="FAQ" title="FAQ">
               <QnaNoticeTab/>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <div className="gallery">
        <div className="gallery-container">
          <h2>Gallery</h2>
      <GallerySlide/>
        </div>
      </div>
    </div>
  );
}

export default Main;
