import React, { useEffect, useState } from 'react'
import { useAllGetData } from '../../hooks/useApi';
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import MyA from './MyA';

const Wrap = styled.div`
  margin: 0 auto;
  width: 100%;
  .slick-prev:before {
    opaicty: 1; // 기존에 숨어있던 화살표 버튼이 보이게
    color: black; // 버튼 색은 검은색으로
    left: 0;
  }
  .slick-next:before {
    opacity: 1;
    color: black;
  }
`;
const GallerySlide = () => {
  const [galleryDatas, setGalleryDatas]=useState([])
 
    const gallerySettings = {
      
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3500,
        responsive: [
          {
            breakpoint: 990,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: true,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
  const [data] = useAllGetData("/board/gallery-posts");
  // useEffect(()=>{
  //   if(data){
  //     setGalleryDatas(data.results)
  //   }
  // },[data])
  if (!data) {
    return null; // 데이터가 없으면 아무것도 렌더링하지 않음
  }

  console.log(data.results)
  return (
    <div>
      <Wrap>
            <Slider {...gallerySettings} dotsClass="gallery-css">
              {
              data && data.results.map((gallery, key)=>
              {return(
               <div className="gallery-img" key={key}>
                {/* <p>1</p> */}
               <MyA to={`/gallery/detail/${gallery.id}`}> 
                 <img src={gallery.thumbnail} />
            
                 </MyA> 
                {/* <p>{gallery.title}</p> 
                </MyA>*/}
              
              </div> 
              )}     
             )}
            </Slider>
          </Wrap>
    </div>
  )
}

export default GallerySlide
