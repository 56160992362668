import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import Menulists from "../../data/Menulist.js";
import SubMenubar from "../layout/SubMenubar.js";
import SubImgbar from "../layout/SubImgbar.js";
import "./Reference.css";
import MyA from "../shared/MyA.js";
import { FaPaperclip } from "react-icons/fa";
import { useGetUserId } from "../../hooks/useApi.js";
import Pagination from "../shared/Pagination.js";
import useResize from "../../hooks/useResize.js";


function Reference() {
  const location = useLocation();
  const base_url = "https://admin.miracledream.net/board/materials";
  const sub_menubar = Menulists.find((m) => m.url == location.pathname);
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );
  const [notices, setNotices] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [baseurl, setbaseurl] = useState(base_url);
  const [currentpage, setcurrentpage] = useState(1);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [loading, setLoading] = useState(true);
  const perpage = 20;
  const [userId, isAdmin, writer] = useGetUserId();
  const [searchSelect, setSearchSelect] = useState("title_only");
  const [searchKeyword, setSearchKeyword] = useState("");
  const handleSelectValue = (e) => setSearchSelect(e.target.value);
  const handleSearchValue = (e) => setSearchKeyword(e.target.value);
  
  async function searchContents() {
    const { data } = await axios.get(
      baseurl + `?search=${searchKeyword}&${searchSelect}=${searchSelect}`
    );
    setNotices(data.results);
    setTotalCount(data.count);
    setNext(data.next);
    setPrevious(data.previous);

    // onChange(searchKeyword)
  }

  const windowWidth = useResize();
  function changeurl(event) {
    setbaseurl(event);
  }

  async function getNotices() {
    setLoading(true);
    const { data } = await axios.get(baseurl);
    setNotices(data.results);
    setTotalCount(data.count);
    setNext(data.next);
    setPrevious(data.previous);
    setLoading(false);
  }

  useEffect(() => {
    getNotices(baseurl);
  }, [baseurl]);
  console.log(notices)
  return (
    <div className="rboard-container">
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="rboard-wrap">
          {isAdmin ? (
            <div className="rboardwrite">
              <MyA to="/reference/insert">
                <button className="rboardwrite-btn" type="button">
                  글쓰기
                </button>
              </MyA>
            </div>
          ) : (
            <></>
          )}
          {windowWidth > 768 ? (
            <>
              <table className="rboard">
                <thead>
                  <tr>
                    <td>
                      <strong>번호</strong>
                    </td>
                    <td>
                      <strong>종류</strong>
                    </td>
                    <td>
                      <strong>제목</strong>
                    </td>
                    <td>
                      <strong>작성자</strong>
                    </td>
                    <td>
                      <strong>파일</strong>
                    </td>
                    <td>
                      <strong>등록일</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {notices && notices.length ? (
                    notices.map((notice, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            {totalcount - perpage * (currentpage - 1) - key}
                          </td>
                          <td>{notice.category.name}</td>
                          <td style={{ textAlign: "left" }}>
                            <MyA to={`detail/${notice.id}`}>{notice.title}</MyA>
                          </td>

                          <td>admin</td>
                          <td>
                            {notice.upfile ? (
                              <span>
                                <FaPaperclip />
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>{notice.created_at.slice(2, 10)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>등록된 글이 없습니다</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="rboardBottom">
                <Pagination
                  notices={notices}
                  previous={previous}
                  base_url={base_url}
                  changeurl={changeurl}
                  setcurrentpage={setcurrentpage}
                  currentpage={currentpage}
                  totalcount={totalcount}
                  perpage={perpage}
                  next={next}
                />
                <div className="searchBox">
                  <select onChange={handleSelectValue}>
                    <option value="title_only">제목</option>
                    <option value="content_only">내용</option>
                    <option value="user_only">작성자</option>
                    <option value="category_only">카테고리</option>                   
                    <option value="title_content_only">제목+내용</option>                  
                  </select>
                  <input value={searchKeyword} onChange={handleSearchValue} />
                  <button type="submit" onClick={searchContents}>
                    찾기
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <table className="rboard">
                <thead>
                  <tr>
                    <td>
                      <strong>번호</strong>
                    </td>
                    <td>
                      <strong>종류</strong>
                    </td>
                    <td>
                      <strong>제목</strong>
                    </td>
                    <td>
                      <strong>작성자</strong>
                    </td>
                    <td>
                      <strong>등록일</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {notices && notices.length ? (
                    notices.map((notice, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            {totalcount - perpage * (currentpage - 1) - key}
                          </td>
                          <td>{notice.category.name}</td>
                          <td style={{ textAlign: "left" }}>
                            <MyA to={`detail/${notice.id}`}>{notice.title}</MyA>
                          </td>
                          <td>admin</td>
                          <td>{notice.created_at.slice(2, 10)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>등록된 글이 없습니다</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="rboardBottom">
                <div className="d-colum w-100">
                  <div className="bottompagination">
                    <Pagination
                      notices={notices}
                      previous={previous}
                      base_url={base_url}
                      changeurl={changeurl}
                      setcurrentpage={setcurrentpage}
                      currentpage={currentpage}
                      totalcount={totalcount}
                      perpage={perpage}
                      next={next}
                    />
                  </div>
                  <div className="searchBox">
                  <select onChange={handleSelectValue}>
                    <option value="title_only">제목</option>
                    <option value="content_only">내용</option>
                    <option value="user_only">작성자</option>
                    <option value="category_only">카테고리</option>                   
                    <option value="title_content_only">제목+내용</option>
                  </select>
                  <input value={searchKeyword} onChange={handleSearchValue} />
                  <button type="submit" onClick={searchContents}>
                    찾기
                  </button>
                </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ContentLayout>
    </div>
  );
}

export default Reference;
