import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css";
import ailogo from "../image/ailogo.png"
const NotFound = () => {
  return (
    <div className="loading404">
    <img src={ailogo} />
         {/* <h1>4<span style={{ color: " rgb(16, 56,172)" }}>0</span>4</h1> */}
      <div className="h4container">
        
        <h4>찾을수 없는 페이지입니다.</h4>
        <h4>요청하신 페이지가 사라졌거나 잘못된 경로를 이용하셨어요</h4>
      </div>
      <Link to="/">GO HOME</Link>
    </div>
  );
};

export default NotFound;
