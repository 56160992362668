import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import Menulists from "../../data/Menulist";
import SubMenubar from "../layout/SubMenubar";
import SubImgbar from "../layout/SubImgbar";
import "./Gallery.css";
import MyA from "../shared/MyA.js";
import Card from "react-bootstrap/Card";
import useResize from "../../hooks/useResize.js";
import Search from "../shared/Search.js";
import { useGetUserId } from "../../hooks/useApi.js";
import Pagination from "../shared/Pagination.js";

function Gallery() {
  const location = useLocation();
  const base_url = "https://admin.miracledream.net/board/gallery-posts";
  const sub_menubar = Menulists.find((m) => m.url == location.pathname);
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );
  const [notices, setNotices] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [baseurl, setbaseurl] = useState(base_url);
  const [currentpage, setcurrentpage] = useState(1);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [loading, setLoading] = useState(true);
  const perpage = 8;
  const [userId, isAdmin, writer] = useGetUserId();
  const windowWidth = useResize();
 
  function changeurl(event) {
    setbaseurl(event);
  }
  async function getNotices() {
    setLoading(true);
    const { data } = await axios.get(baseurl);
    setNotices(data.results);
    setTotalCount(data.count);
    setNext(data.next);
    setPrevious(data.previous);
    setLoading(false);
  }
  useEffect(() => {
    getNotices(baseurl);
  }, [baseurl]);

  // const [searchSelect, setSearchSelect] = useState("title_only");
  // const [searchKeyword, setSearchKeyword] = useState("");

  // const handleSelectValue = (e) => setSearchSelect(e.target.value);
  // const handleSearchValue = (e) => setSearchKeyword(e.target.value);

  return (
    <div className="galleryContainer">
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="galleryWrap">
        {isAdmin ? (
            <div className="boardwrite">
              <MyA to="/gallery/insert">
                <button className="boardwrite-btn" type="button">
                  글쓰기
                </button>
              </MyA>
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            {notices && notices.length ? (
              notices.map((notice, key) => {
                return (
                  <div className="col-md-6 col-lg-3 mb-3"  key={notice.id}>
                    <MyA to={`detail/${notice.id}`}>
                      <Card>
                        <Card.Img
                          variant="top"
                          src={notice.thumbnail}
                          className="gallery-thumbnail"
                        />
                        <Card.Body>
                          <Card.Text>{notice.title}</Card.Text>
                        </Card.Body>
                      </Card>
                    </MyA>
                  </div>
                );
              })
            ): (
              <p style={{fontSize: 18,paddingBottom: 100,paddingTop:40, borderBottom: '1.5px solid black'}}>등록된 갤러리가 없습니다.</p>
            )}
          </div>
          {windowWidth > 768 ? (
            <>
              <div className="boardBottom">
                <Pagination
                  notices={notices}
                  previous={previous}
                  base_url={base_url}
                  changeurl={changeurl}
                  setcurrentpage={setcurrentpage}
                  currentpage={currentpage}
                  totalcount={totalcount}
                  perpage={perpage}
                  next={next}
                />
                <Search
                  baseurl={baseurl}
                  setNotices={setNotices}
                  setTotalCount={setTotalCount}
                  setNext={setNext}
                  setPrevious={setPrevious}
                />
              </div>
            </>
          ) : (
            <>
              <div className="boardBottom">
                <div className="d-colum w-100">
                  <div className="bottompagination">
                    <Pagination
                      notices={notices}
                      previous={previous}
                      base_url={base_url}
                      changeurl={changeurl}
                      setcurrentpage={setcurrentpage}
                      currentpage={currentpage}
                      totalcount={totalcount}
                      perpage={perpage}
                      next={next}
                    />
                  </div>
                  <div className="searchSBox">
                    <Search
                      baseurl={baseurl}
                      setNotices={setNotices}
                      setTotalCount={setTotalCount}
                      setNext={setNext}
                      setPrevious={setPrevious}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ContentLayout>
    </div>
  );
}

export default Gallery;
