import React from 'react'
import { Link } from 'react-router-dom'

const MyA = (props) => {
  return (
      <Link {...props} style={{
        color: "black", textDecoration: "none"
      }}></Link>
    )
}

export default MyA