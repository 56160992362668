const Menulists =[
    {
     mainMenu: "연구소 소개",
     title: "연구소 소개",
     url: "/introduce"     
    },
   {
     mainMenu: "연구소 소개",
     title: "대표소개",
     url: "/introduce/ceo"     
   },
    {
    mainMenu: "연구소 소개",
    title: "오시는길",
    url: "/location"     
  },
   {
     mainMenu: "인공지능",
     title: "인공지능",
     url: "/ai"     
   },
   {
    mainMenu: "인공지능",
    title: "트랜드",
    url: "/trend"     
  },
   {
    mainMenu: "연구소 소식",
    title: "공지사항 및 소개",
    url: "/notice"     
   },
   {
    mainMenu: "연구소 소식",
    title: "갤러리",
    url: "/gallery",
   },
   {
     mainMenu: "게시판",
     title: "게시판",
     url: "/board",   
   }
   ,{
    mainMenu: "게시판",
    title: "자료실",
    url: "/reference"     
   },
   {
     mainMenu: "게시판",
     title: "FAQ",
     url: "/fnq",
}
  ,{
    mainMenu: "게시판",
    title: "Q&A",
    url: "/qnaboard",
   }
   
]
export default Menulists;