import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import Menulists from "../../data/Menulist.js";
import SubMenubar from "../layout/SubMenubar.js";
import SubImgbar from "../layout/SubImgbar.js";
import "./FNQ.css";

import Accordion from 'react-bootstrap/Accordion';
import { useAllGetData } from "../../hooks/useApi.js";
function FNQ() {
  const location = useLocation();
  const sub_menubar = Menulists.find((m) => m.url == location.pathname);
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );

  const [notices, setNotices] = useState([]);
  const [data] = useAllGetData("/board/faqs", "");
  const [fnqDatas, setFnqDatas] = useState([])

  
  useEffect(()=>{
    if(data){
      setFnqDatas(data.results);
    }
  },[data])

  return (
    <div className="fnqboard-container">
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="fnqboard-wrap">
        {fnqDatas.map((fnq,index)=>{
        return( <Accordion className="mb-2" key={index} >
          <Accordion.Item eventKey={index} className="item">
            <Accordion.Header className="accordionHead">{fnq.title}</Accordion.Header>
            <Accordion.Body style={{textAlign: 'left'}}>
              {fnq.content}
            </Accordion.Body>
          </Accordion.Item >
        </Accordion>
         ) })} 
        </div>
      </ContentLayout>
    </div>
  );
}

export default FNQ;
