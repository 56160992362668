import axios from "axios";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SecretChange.css";
import SubImgbar from "../layout/SubImgbar";
import ContentLayout from "../shared/ContentLayout";
import { useGetUserId, useUpdateData } from "../../hooks/useApi";
import { axiosInstance } from "../../axios";

function SecretChange() {
  const navigate = useNavigate();
  const InputOldPw = useRef(null);
  const InputPw = useRef(null);
  const InputRePw = useRef(null);
  const [pwValid, setPwValid] = useState(() => false);
  const [pwReCheck, setPwReCheck] = useState(() => true);
  const [userId] = useGetUserId();
  // const updateData = useUpdateData("/api/user/change-password", null, "/mypage");

  const handlePasswordChange = () => {
    if (InputPw.current && InputPw.current.value) {
      const passwd = InputPw.current.value;
      const regex =
        /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{7,20}$/;
      const pwValid = regex.test(passwd);
      if (pwValid) {
        setPwValid(true);
      } else {
        setPwValid(false);
      }
    }
  };
  const pwCheckHandler = () => {
    if (
      InputPw.current.value &&
      InputRePw.current.value
    ) {
      setPwReCheck(
        InputPw.current.value === InputRePw.current.value &&
          InputRePw.current.value.length > 0
      );
    } else if(InputRePw.current.value.length == 0){
      setPwReCheck(false);
    }else{
      setPwReCheck(false);
    }
  
  };


  const handleRegisterForm = async (e) => {
    e.preventDefault();
    const info = {
      old_password: InputOldPw.current.value,
      new_password: InputPw.current.value,
    };
     
    await axiosInstance.put("/api/user/change-password/", info)
     .then((res)=>{
      if(res.status ===200){
        window.alert("비밀번호가 변경되었습니다.");
        window.location = "/mypage";
      }
     }).catch(
      (error) =>{
        if(error.response.status === 400){
          window.alert("기존 비밀번호가 일치하지않거나 새로운 비밀번호와 기존비밀번호와 일치합니다.");
          console.log(error)
        }else if(error.response.status === 401){
          // window.alert("기존 비밀번호가 일치하지않습니다.");
          console.log(error)
        }else{
          console.log(error);

        }
    })
     
  };
  return (
    <div className="sprofile-wrap">
      <SubImgbar />
      <ContentLayout>
        <div className="sprofile-box">
          <div className="contentMypageSubtitle">
            <h2 className="contentMypageboard">비밀번호변경</h2>
          </div>
          <form
            method="post"
            onSubmit={handleRegisterForm}
            encType="multipart/form-data"
          >
            <table>
              <thead>
                <tr>
                  <td>기존 비밀번호</td>
                  <td>
                    <input type="password" ref={InputOldPw} required />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>새로운 비밀번호</td>
                  <td>
                    <input
                      type="password"
                      ref={InputPw}
                      required
                      onChange={handlePasswordChange}
                    />
                    {/* <div className="errorMessage">
                      {!pwValid &&(
                        <div style={{ fontSize: 12 }}>
                          영문,숫자,특수문자 포함 8자이상 20자이하 입력해주세요
                        </div>
                      )}
                    </div> */}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span className="errorMessage">
                      {!pwValid && (
                        <div style={{ fontSize: 12 }}>
                          영문,숫자,특수문자 포함 8자이상 20자이하 입력해주세요
                        </div>
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>비밀번호 확인</td>
                  <td>
                    <input
                      type="password"
                      ref={InputRePw}
                      onChange={pwCheckHandler}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span className="errorMessage">
                    {!pwReCheck && (
                        <div style={{ fontSize: 12 }}>
                          비밀번호가 일치하지 않습니다.
                        </div>
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="btn-container d-flex justify-content-between">
              <div></div>
              <div className="btn-wrap d-flex">
                <button type="submit">수정</button>
                <button type="button" onClick={() => navigate(-1)}>
                  취소
                </button>
              </div>
            </div>
          </form>
        </div>
      </ContentLayout>
    </div>
  );
}

export default SecretChange;
