import React from "react";
import Menulists from "../../data/Menulist";
import SubMenubar from "../layout/SubMenubar";
import SubImgbar from "../layout/SubImgbar";
import { useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout";
import chatGPT from "../image/chatGPT.jpg"
import deepmachine from "../image/deepmachine.png"
import NLP from "../image/NLP.jpeg"
import puzzy from "../image/puzzy.jpg"
import "./Trend.css"
const Trend = () => {
  const location = useLocation();
  const sub_menubar = Menulists.find((m) => m.url == location.pathname);
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );
  return (
    <div>
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
       <div className="trend-container">
       <div className='row'>
       <div className='col-lg-6 col-md-6 col-sm-12 order-md-1'  
            style={{
                    backgroundImage : `url(${chatGPT})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top center",
                    backgroundSize: "cover",
                    height: "27rem",
                  }}>
             {/* <h4 className='fs-1 fw-bold'>Chat GPT</h4> */}
          </div>  
         <div className='col-lg-6 col-md-6 col-sm-12 fs-5 mt-5 order-md-2 trend-content' >  
            <h4 className='fs-2 fw-bold'>Chat GPT</h4>
              <p className="trend-p"><br/>챗GPT는 미국의 AI 연구재단 OpenAI(오픈에이아이)가 2022년 11월 공개한 초거대 언어모델 GPT-3.5 기반 대화형 인공지능 챗봇입니다.<br/>
                현재 챗GPT 홈페이지(https://chat.openai.com/chat)에서 누구나 가입 후 무료로 이용할 수 있다.<br/>
               GPT는 ‘Generative Pre-trained Transformer’의 약자로<br/>
              머신러닝을 통해 방대한 데이터를 ‘미리 학습(Pre-trained)’해 이를 문장으로
              ‘생성(Generative)’하는 생성 AI이다. <br/> 
              사용자가 채팅 하듯 질문을 입력하면 챗GPT는 학습한 데이터를 기반으로 ‘사람처럼’ 문장을 만들어 답을 해준다.</p>
          </div>
       </div>
       <div className='row'>
         <div className='col-lg-6 col-md-6 col-sm-12 order-md-2'
           style={{
                    backgroundImage : `url(${deepmachine})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    height: "27rem",
                    backgroundColor: "rgba(0,0,0)"
                  }}>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 fs-5 mt-5  order-md-1 trend-content-wrap' >  
          <div className="trend-content">
              <h4 className='fs-2 fw-bold'>딥러닝머신러닝</h4>
              <p className="trend-p">
                <br/>
              인공지능의 한 분야이다. <br/>
              1959년 아서 사무엘은 기계학습을 “컴퓨터에 명시적인 프로그램 없이 배울 수 있는 능력을 부여하는 연구 분야”라고 정의하였다.<br/>
              즉 사람이 학습하듯이 컴퓨터에도 데이터들을 줘서 학습하게 함으로써 새로운 지식을 얻어내게 하는 분야이다.
              </p>
              </div>
              
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 order-md-1'
            style={{
              backgroundImage : `url(${NLP})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
              height: "27rem",
              }}> 
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 fs-7 mt-5 order-md-2 trend-content-wrap'>  
              <div className="trend-content">
              <h4 className='fs-2 fw-bold'>자연어처리</h4>
              <p className="trend-p">자연어 처리(NLP)는 컴퓨터가 인간의 언어를 이해, 생성, 조작할 수 있도록 해주는 인공 지능(AI)의 한 분야이다.<br/>
               자연어 처리는 자연어 텍스트 또는 음성으로 데이터를 상호 연결하는 것으로 '언어 입력(language in)'이라고도 한다.<br/>
               대부분의 소비자는 자신도 모르는 사이에 NLP와 상호 작용을 하고 있다. <br/>
               예를 들어, NLP는 Oracle Digital Assistant(ODA)나 Siri, Cortana, Alexa와 같은 가상 도우미의 핵심 기술이다.<br/>
              이러한 가상 도우미에게 질문을 하면 NLP를 통해 사용자의 요청을 이해할 수 있을 뿐만 아니라 자연어로 응답할 수 있다.<br/>
             </p>
             </div>
          </div>  
       </div>
       <div className='row'>
         <div className='col-lg-6 col-md-6 col-sm-12 order-md-2'
             style={{
              backgroundImage : `url(${puzzy})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top center",
              backgroundSize: "cover",
              height: "27rem"          
              }}
         >
          </div>
          <div className='col-lg-6  col-md-6 col-sm-12fs-5 mt-5 order-md-1 trend-content-wrap'>  
          <div className="trend-content">
              <h4 className='fs-2 fw-bold'>퍼지</h4>
              <p className="trend-p">
              <br/>
              퍼지 이론은 1965년에 Lotfi Zadeh에 의해제안된 이론으로,<br/>
              불확실성과 애매모호함이 있는 문제에 대해 수학적인 접근 방식을 제공한다.
              전통적인 논리 시스템이 0(거짓)과 1(참)으로 이루어진 이진시스템을 가지고 있는 반면,<br/>
              퍼지 이론은 0과 1 사이의 모든 값들을 인지할 수 있게 해서 문제에 대한 보다 다양한 접근을 가능하게 해준다.<br/>
              현재 퍼지 이론은 다음과 같은 분야에서 적용되고 있다.
              </p>
            </div>
          </div>
        </div>
        </div>
      </ContentLayout>
    </div>
  );
};

export default Trend;
