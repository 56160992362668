import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import Menulists from "../../data/Menulist";
import SubMenubar from "../layout/SubMenubar";
import SubImgbar from "../layout/SubImgbar";
import MyA from "../shared/MyA.js";
import "./GalleryDetail.css";
import { useAllGetData, useDelData, useGetUserId } from "../../hooks/useApi.js";
function GalleryDetail() {
  const location = useLocation();
  const boardId = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );
  const sub_menubar = Menulists.find(
    (m) => m.url == `/${location.pathname.split("/")[1]}`
  );
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );
  // const [content, setContent] = useState([]);
  const [data] = useAllGetData("/board/gallery-posts", boardId);
  const [user, setUser] = useState("");
  const [date, setDate] = useState("");
  const [userId, isAdmin, writer] = useGetUserId();
  const [contents, setContents, delContent] = useDelData("/board/gallery-posts",boardId,"/gallery");
  useEffect(() => {
    if (data) {
      setUser(data.user.name);
      setDate(data.created_at);
    }
  }, [data]);
  // async function getContent() {
  //   const { data } = await axios.get(
  //     "http://localhost:5000/community/board/detail"
  //   );
  //   setContent(data);
  // }

  // useEffect(() => {
  //   getContent();
  // }, [content]);

  //const navigate = useNavigate();
  return (
    <div className="boardDetail">
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="boardDetail-wrap">
          <table className="boardDetail">
            <thead>
              <tr>
                <td colSpan={4}>
                  <strong>{data.title}</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={3}>작성자 : {user}</td>
                <td style={{ textAlign: "right" }}>
                  작성일 : {date.slice(2, 10)}
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div
                    className="ql-editor"
                    dangerouslySetInnerHTML={{ __html: data.content }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="boardBtn-wrap">
            {isAdmin ? (
              <>
                <MyA to={`/gallery/update/${boardId}`}>
                  <button type="button" className="list">
                    수정
                  </button>
                </MyA>
                  <button type="button" className="del" onClick={delContent}>
                    삭제
                  </button>
              </>
            ) : (
              <></>
            )}
            <MyA to="/gallery">
              <button type="button" className="list">
                목록
              </button>
            </MyA>
          </div>
        </div>
        {/* <>    <h2>공지사항</h2></>
    
         {content.map((con, key) => {
          {
            return (
              <table className="boardDetail" key={key}>
                <thead>
                  <tr>
                    <td>SUBJECT</td>
                    <td>
                      <strong>{con.title}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>WRITER</td>
                    <td>shoes shop</td>
                  </tr>
                  <tr>
                    <td>DATE</td>
                    <td style={{ color: "grey" }}>{con.regdate}</td>
                    <td>VIEW</td>
                    <td style={{ color: "grey" }}>8207</td>
                  </tr>
                </thead>
                <tbody>
                  <td
                    colSpan={"4"}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <p style={{ marginTop: "20px", marginBottom: "20px" }}>
                      {con.content}
                    </p>
                  </td>
                </tbody> 
              </table>
            );
          }
        })}

        <button
          onClick={() => {
            navigate(-1);
          }}
          className="listBox"
        >
          LIST
        </button>*/}
      </ContentLayout>
    </div>
  );
}

export default GalleryDetail;
