import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../../axios";
import "./NoticeTab.css";
import MyA from "../../shared/MyA";
import useResize from "../../../hooks/useResize";
const QnaNoticeTab = () => {
  const [boards, setBoards] = useState([]);
  const windowWidth = useResize();

  useEffect(() => {
    const getBoard = async () => {
      try {
        const { data } = await axiosInstance.get("/board/faqs/");
        setBoards(data.results.slice(0, 10));
      } catch (error) {
        console.log(error);
      }
    };
    getBoard();
  }, []);

  const renderBoards = () => {
    if (windowWidth > 1440) {
      return boards.map((board, key) => (
        <MyA to={"/fnq"} key={key}>
          <div className="noticetab d-flex">
            <strong>
              {board.title.length > 26
                ? board.title.slice(0, 26) + "..."
                : board.title}
            </strong>
            <span></span>
          </div>
        </MyA>
      ));
    } else if (windowWidth <= 1440 && windowWidth > 1200) {
      return boards.slice(0, 9).map((board, key) => (
        <MyA to={"/fnq"} key={key}>
          <div className="noticetab d-flex">
            <strong>
              {board.title.length > 25
                ? board.title.slice(0,25) + "..."
                : board.title}
            </strong>
            <span></span>
          </div>
        </MyA>
      ));
      //}
      // else if (windowWidth >= 1000 && windowWidth <= 1200) {
      //   return boards.slice(0, 6).map((board, key) => (
      //     <MyA to={`/board/detail/${board.id}`} key={key}>
      //       <div className="noticetab d-flex">
      //         <strong>
      //           {board.title.length > 16
      //             ?board.title.slice(0, 16) + "..."
      //             : board.title}
      //         </strong>
      //         <span>{board.created_at.slice(0, 10)}</span>
      //       </div>
      //     </MyA>
      //   ));
    } else if (
      (windowWidth <= 1200 && windowWidth > 900) ||
      windowWidth < 280
    ) {
      return boards.slice(0, 7).map((board, key) => (
        <MyA to={"/fnq"} key={key}>
          <div className="noticetab d-flex">
            <strong>
              {windowWidth < 280 && board.title.length > 14
                ? board.title.slice(0, 14) + "..."
                : windowWidth < 1000 &&
                  windowWidth > 900 &&
                  board.title.length > 12
                ? board.title.slice(0, 12) + "..."
                : board.title.length > 26
                ? board.title.slice(0, 20) + "..."
                : board.title}
            </strong>
            <span></span>
          </div>
        </MyA>
      ));
    } else if (windowWidth < 900) {
      return boards.slice(0, 8).map((board, key) => (
        <MyA to={"/fnq"} key={key}>
          <div className="noticetab d-flex">
            <strong>
              {windowWidth <= 330 && windowWidth > 280 && board.title.length > 13
                ? board.title.slice(0, 13) + "..."
                : windowWidth <= 390 && board.title.length > 18
                ? board.title.slice(0, 18) + "..."
                // : windowWidth <= 521 && board.title.length > 25
                // ? board.title.slice(0, 25) + "..."
                // : windowWidth <= 768 && board.title.length > 25
                // ? board.title.slice(0, 25) + "..."
                : windowWidth <= 900 && board.title.length > 20
                ? board.title.slice(0, 20) + "..."
                : board.title.length > 20
                ? board.title.slice(0, 25) + "..."
                : board.title}
            </strong>
            <span></span>
          </div>
        </MyA>
      ));
    }
  };
  return <div className="noticetab-container">{renderBoards()}</div>;
};

export default QnaNoticeTab;
