import React, { useState } from 'react'
import SubImgbar from "../layout/SubImgbar"
import "./Registerconfirm.css"
import { useNavigate } from "react-router-dom";


const Registerconfirm = () => {

const navigate = useNavigate();
const [privacyCheck, setPrivacyCheck] = useState(false);
const [useCheck, setUseCheck] = useState(false);

const registerHandleForm = (e) =>{
   e.preventDefault();
   if(!privacyCheck){
    alert('개인정보취급방침에 동의해주실 바랍니다.')
   }else if(!useCheck){
     alert('이용약관에 동의해주실 바랍니다.')
   }else{
    navigate("/register")
   }
}
  return (
    <div className='registerconfirm'>
      <SubImgbar/>
      <div className='registerconfirm-container'>
      <h2>회원가입신청</h2>
      <form className="contract" method="post" onSubmit={registerHandleForm}>
         <h2 className="contract-title">개인정보취급방침</h2>
         <div className='privacyStatement'>
          <h6>개인정보 수집 이용</h6>
          <br/>
          <p>:::: Miracle인공지능연구소 개인정보취급방침 ::::
           <br/> <br/>
           개인정보의 수집목적 및 이용목적
           <br/><br/>
          ① 미라클인공지능연구소는 회원님께 최대한으로 최적화되고 맞춤화된 서비스를 제공하기 위하여 다음과 같은 목적으로 개인정보를 수집하고 있습니다. 성명, 아이디, 비밀번호 : 회원제 서비스 이용에 따른 본인 식별 절차에 이용 이메일주소, 이메일 수신여부, 전화번호 : 고지사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사소통 경로의 확보, 새로운 서비스이나 행사 정보의 안내 주소, 전화번호 : 개인맞춤 서비스를 제공하기 위한 자료
           <br/>
          ② 단, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다. 개인정보의 수집범위 미라클인공지능연구소는 별도의 회원가입 절차 없이 대부분의 콘텐츠에 자유롭게 접근할 수 있습니다. 의 회원제 서비스를 이용하시고자 할 경우 다음의 정보를 입력해주셔야 하며 선택항목을 입력하시지 않았다 하여 서비스 이용에 제한은 없습니다.
          <br/><br/>
          회원 가입시 수집하는 개인정보의 범위
          <br/><br/>
          필수항목 : 희망 ID, 비밀번호, 성명, 전화번호, 이메일주소, 이메일 수신 여부
          <br/><br/>
          쿠키에 의한 개인정보 수집
          <br/><br/>
          ① 쿠키(cookie)란?<br/>
          미라클 인공자능 연구소는 귀하에 대한 정보를 저장하고 수시로 찾아내는 쿠키(cookie)를 사용합니다. 쿠키는 웹사이트가 귀하의 컴퓨터 브라우저로 전송하는 소량의 정보입니다. 귀하께서 웹사이트에 접속을 하면 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다. 쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는 않습니다. 또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저 상단의 도구, 인터넷옵션 탭(option tab)에서 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.
          ② 미라클인공지능연구소의 쿠키(cookie) 운용 미라클인공지능연구소는 이용자의 편의를 위하여 쿠키를 운영합니다. 이 쿠키를 통해 수집하는 정보는 회원 ID에 한하며, 그 외의 다른 정보는 수집하지 않습니다. 이 쿠키(cookie)를 통해 수집한 회원 ID는 다음의 목적을 위해 사용됩니다. 개인의 관심 분야에 따라 차별화된 정보를 제공 회원과 비회원의 접속빈도 또는 머문시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 타겟(target) 서비스에 활용 구입한 논문지들에 대한 정보와 관심있게 둘러본 품목들에 대한 자취를 추적하여 다음번 방문 때 개인 맞춤 서비스를 제공 회원들의 습관을 분석하여 서비스 개편 등의 척도 게시판 글 등록쿠키는 브라우저의 종료시나 로그아웃시 만료됩니다.
          <br/><br/>
          게시물
          <br/><br/>
          ① 미라클인공지믕연구소 홈페이지는 회원의 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다. 그러나 다음의 경우는 그렇지 아니합니다. 스팸(spam)성 게시물 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 글 동의 없는 타인의 신상공개, 제 3자의 저작권 등 권리를 침해하는 내용, 기타 게시판 주제와 다른 내용의 게시물 비밀번호 힌트용 질문과 답변 : 비밀번호를 잊은 경우의 신속한 처리를 위한 내용 다른 주제의 게시판으로 이동 가능한 내용일 경우 해당 게시물에 이동 경로를 밝혀 오해가 없도록 하고 있습니다. 그 외의 경우 명시적 또는 개별적인 경고 후 삭제 조치할 수 있습니다.
          ② 근본적으로 게시물에 관련된 제반 권리와 책임은 작성자 개인에게 있습니다. 또한 게시물을 통해 자발적으로 공개된 정보는 보호받기 어려우므로 정보 공개 전에 심사 숙고하시기 바랍니다.
          <br/><br/>
          개인정보의 보유기간 및 이용기간
          <br/><br/>
          ① 귀하의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기됩니다. 단, 상법 등 관련법령의 규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다. 회원가입정보의 경우, 회원가입을 탈퇴하거나 회원에서 제명된 경우 등 일정한 사전에 보유목적, 기간 및 보유하는 개인정보 항목을 명시하여 동의를 구합니다. 계약 또는 청약철회 등에 관한 기록 : 5년 보관대금결제 및 재화 등의 공급에 관한 기록 : 5년 보관 회원의 불만 또는 분쟁처리에 관한 기록 : 3년 보관<br/>
          ② 귀하의 동의를 받아 보유하고 있는 거래정보 등을 귀하께서 열람을 요구하는 경우 미라클인공지눙연구소눈 지체없이 그 열람,확인 할 수 있도록 조치합니다. 미라클인공지능연구소는 이용자의 개인정보를 안전하게 관리하기 위하여 최선을 다하며, 정보통신망법 및 개인정보보호법에서 요구하는 수준 이상으로 개인정보를 보호하고 있습니다.</p>
           </div>
            <div className='agreebox'>
               <input type="checkbox" className="chk" onChange={(e)=> setPrivacyCheck(e.target.checked)}/> 위 개인정보 수집 이용에 동의합니다.
            </div>    
          <div>
        <h2 className="contract-title">이용약관</h2>
         <div className='privacyStatement'>
          <h5>이용약관</h5>
          <br/>
          <p>:::: 미라클인공지능연구소 이용약관 ::::
           <br/><br/>
           이용약관
           <br/><br/>
          제 1 장 총 칙제 1 조 (목적)<br/>
           이 약관은 미라클인공지능연구소(이하 '회사')와 이용고객(이하 '회원')간에 회사가 제공하는 인터넷서비스(이하 '서비스')의 이용조건 및 절차, 회사와 회원간의 권리,의무 및 책임사항 기타 필요한 사항을 규정함을 목적으로 합니다.
           <br/><br/>
           제 2 조 (이용약관의 효력 및 변경)<br/>
           (1) 이 약관은 미라클인공지능연구소 웹사이트(miracledream.co.kr 이하 "인공지능연구소")에서 온라인으로 공시함으로써 효력을 발생하며, 합리적인 사유가 발생할 경우 관련법령에 위배되지 않는 범위 안에서 개정될 수 있습니다. 개정된 약관은 온라인에서 공지함으로써 효력을 발휘하며, 이용자의 권리 또는 의무 등 중요한 규정의 개정은 사전에 공지합니다.
           <br/>
           (2) 회사는 합리적인 사유가 발생될 경우에는 이 약관을 변경할 수 있으며, 약관을 변경할 경우에는 지체 없이 이를 사전에 공시합니다.
           <br/>
           (3) 이 약관에 동의하는 것은 정기적으로 웹을 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 회사에서 책임지지 않습니다.
           <br/>
           (4) 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴(해지)를 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이후에도 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.
           <br/><br/>
           제 3 조 (약관외 준칙)<br/>
           (1) 이 약관은 회사가 제공하는 개별서비스에 관한 이용안내(이하 서비스별 안내라 합니다)와 함께 적용합니다.(2) 이 약관에 명시되지 아니한 사항에 대해서는 관계법령 및 서비스별 안내의 취지에 따라 적용할 수 있습니다.
           <br/><br/>
           제 4 조 (용어의 정의)<br/>
           (1) 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
           <br/>
           1. '이용고객'이라 함은 서비스를 이용하는 이용자(기업 또는 개인)를 말합니다.
           <br/>
           2. '이용계약'이라 함은 서비스 이용과 관련하여 회사와 이용고객 간에 체결 하는 계약 말합니다.
           <br/>
           3. '이용자번호(ID)'라 함은 이용고객의 식별과 이용고객의 서비스 이용을 위하여 이용고객이 선정하고 회사가 부여하는 문자와 숫자의 조합을 말합니다.
           <br/>
           4. '비밀번호'라 함은 이용고객이 부여 받은 이용자번호와 일치된 이용고객 임을 확인하고 이용고객의 권익보호를 위하여 이용고객이 선정한 문자와 숫자의 조합을 말합니다.
           <br/>
           5. '단말기'라 함은 회사가 제공하는 서비스를 이용하기 위해 이용고객이 설치한 개인용 컴퓨터 및모뎀 등을 말합니다.
           <br/>
           6. '해지'라 함은 회사 또는 회원이 이용계약을 해약하는 것을 말합니다.
           <br/>
           (2) 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계법령 및 서비스별 안내에서 정하는 바에 의합니다.
           <br/><br/>
           제 2 장 이용계약 체결제 <br/><br/>
           제 5 조 (이용 계약의 성립)<br/>
           (1) 이용계약은 이용고객의 본 이용약관 내용에 대한 동의와 이용신청에 대하여 회사의 이용승낙으로 성립합니다.
           <br/>
           (2) 본 이용약관에 대한 동의는 이용신청 당시 해당 인공지능연구소의 '동의함' 버튼을 누름으로써 의사표시를 합니다.
           <br/>
           (3) 이용고객이 기업일 경우 이용계약의 성립에 의해 이용약관을 동의한 것으로 간주합니다.
           <br/><br/>
           제 6 조 (서비스 이용 신청)
           <br/>
           (1) 회원으로 가입하여 본 서비스를 이용하고자 하는 이용고객은 회사에서 요청하는 제반 정보(이름, 연락처 등)를 제공하여야 합니다.
           <br/>
           (2) 모든 회원은 반드시 회원 본인의 이름과 주민등록번호를 제공하여야만 서비스를 이용할 수 있으며, 실명으로 등록하지 않은 사용자는 일체의 권리를 주장할 수 없습니다.
           <br/>
           (3) 회원가입은 반드시 실명으로만 가입할 수 있으며 회사는 실명확인조치를 할 수 있습니다.
           <br/>
           (4) 타인의 명의(이름 및 주민등록번호)를 도용하여 이용신청을 한 회원의 모든 ID는 삭제되며, 관계법령에 따라 처벌을 받을 수 있습니다.
           <br/><br/>
           제 7 조 (개인정보의 보호 및 사용)회사는 관계법령이 정하는 바에 따라 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기 위해 노력합니다. 이용자 개인정보의 보호 및 사용에 대해서는 관련법령 및 회사의 개인정보 보호정책이 적용됩니다. 단, 회사의 공식사이트이외의 웹에서 링크된 사이트에서는 회사의 개인정보 보호정책이 적용되지 않습니다. 또한 회사는 이용자의 귀책사유로 인해 노출된 정보에 대해서 일체의 책임을 지지 않습니다.
           <br/><br/>
           제 8 조 (이용 신청의 승낙과 제한)
           <br/>
           (1) 회사는 제 6조의 규정에 의한 이용신청고객에 대하여 업무 수행상 또는 기술상 지장이 없는 경우에 원칙적으로 접수순서에 따라 서비스 이용을 승낙합니다.
           <br/>
           (2) 회사는 아래사항에 해당하는 경우에 대해서 승낙하지 아니 합니다.
           <br/>
           1. 실명이 아니거나 타인의 명의를 이용하여 신청한 경우
           <br/>
           2. 이용계약 신청서의 내용을 허위로 기재한 경우
           <br/>
           3. 사회의 안녕과 질서, 미풍양속을 저해할 목적으로 신청한 경우
           <br/>
           4. 부정한 용도로 본 서비스를 이용하고자 하는 경우
           <br/>
           5. 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우
           <br/>
           6. 기타 규정한 제반 사항을 위반하며 신청하는 경우
           <br/>
           7. 본 서비스와 경쟁관계에 있는 이용자가 신청하는 경우
           <br/>
           8. 기타 규정한 제반 사항을 위반하며 신청하는 경우
           <br/>
           (3) 회사는 서비스 이용신청이 다음 각 호에 해당하는 경우에는 그 신청에 대하여 승낙 제한사유가 해소될 때까지 승낙을 유보할 수 있습니다.
           <br/>
           1. 회사가 설비의 여유가 없는 경우
           <br/>
           2. 회사의 기술상 지장이 있는 경우
           <br/>
           3. 기타 회사의 귀책사유로 이용승낙이 곤란한 경우제 
           <br/><br/>
           제 9 조 (이용자ID 부여 및 변경 등)
           <br/>
           (1) 회사는 이용고객에 대하여 약관에 정하는 바에 따라 이용자 ID를 부여합니다.
           <br/>
           (2) 이용자ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경 하고자 하는 경우에는 해당 ID를 해지하고 재가입해야 합니다.
           <br/>
           (3) 이용자ID는 다음 각 호에 해당하는 경우에는 이용고객 또는 회사의 요청으로 변경할 수 있습니다.
           <br/>
           1. 이용자ID가 이용자의 전화번호 또는 주민등록번호 등으로 등록되어 사생활침해가 우려되는 경우
           <br/>
           2. 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우
           <br/>
           3. 기타 합리적인 사유가 있는 경우
           <br/>
           (4) 서비스 이용자ID 및 비밀번호의 관리책임은 이용자에게 있습니다. 이를 소홀이 관리하여 발생하는 서비스 이용상의 손해 또는 제3자에 의한 부정이용 등에 대한 책임은 이용자에게 있으며 회사는 그에 대한 책임을 일절 지지 않습니다.
           <br/>
           (5) 기타 이용자 개인정보 관리 및 변경 등에 관한 사항은 서비스별 안내에 정하는 바에 의합니다.
           <br/><br/>
           제 3 장 계약 당사자의 의무제 
           <br/>
           제 10 조 (회사의 의무)
           <br/>
           (1) 회사는 이용고객이 희망한 서비스 제공 개시일에 특별한 사정이 없는 한 서비스를 이용할 수 있도록 하여야 합니다.
           <br/>
           (2) 회사는 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가 생기거나 멸실된 때에는 부득이한 사유가 없는 한 지체없이 이를 수리 또는 복구합니다.
           <br/>
           (3) 회사는 개인정보 보호를 위해 보안시스템을 구축하며 개인정보 보호정책을 공시하고 준수합니다.
           <br/>
           (4) 회사는 이용고객으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 이용자에게 그 사유와 처리일정을 통보하여야 합니다.
           <br/><br/>
           제 11 조 (이용자의 의무)<br/>
           (1) 이용자는 회원가입 신청 또는 회원정보 변경 시 실명으로 모든 사항을 사실에 근거하여 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 일체의 권리를 주장할 수 없습니다.
           <br/>
           (2) 회원은 본 약관에서 규정하는 사항과 기타 회사가 정한 제반 규정, 공지사항 등 회사가 공지하는 사항 및 관계법령을 준수하여야 하며, 기타 회사의 업무에 방해가 되는 행위, 회사의 명예를 손상시키는 행위를 해서는 안됩니다.
           <br/>
           (3) 회원은 주소, 연락처, 전자우편 주소 등 이용계약사항이 변경된 경우에 해당 절차를 거쳐 이를 회사에 즉시 알려야 합니다.
           <br/>
           (4) 회사가 관계법령 및 '개인정보 보호정책'에 의거하여 그 책임을 지는 경우를 제외하고 회원에게 부여된 ID의 비밀번호 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
           <br/>
           (5) 회원은 회사의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과에 대해 회사는 책임을 지지 않습니다. 또한 회원은 이와 같은 영업활동으로 회사가 손해를 입은 경우, 회원은 회사에 대해 손해배상의무를 지며, 회사는 해당 회원에 대해 서비스 이용제한 및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.
           <br/>
           (6) 회원은 회사의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.
           <br/>
           (7) 회원은 회사 및 제 3자의 지적 재산권을 침해해서는 안됩니다.
           <br/>
           (8) 회원은 다음 각 호에 해당하는 행위를 하여서는 안되며, 해당 행위를 하는 경우에 회사는 회원의 서비스 이용제한 및 적법 조치를 포함한 제재를 가할 수 있습니다.
           <br/>
           1. 회원가입 신청 또는 회원정보 변경 시 허위내용을 등록하는 행위
           <br/>
           2. 다른 이용자의 ID, 비밀번호, 주민등록번호를 도용하는 행위
           <br/>
           3. 이용자 ID를 타인과 거래하는 행위
           <br/>
           4. 회사의 운영진, 직원 또는 관계자를 사칭하는 행위
           <br/>
           5. 회사로부터 특별한 권리를 부여받지 않고 회사의 클라이언트 프로그램을 변경하거나, 회사의 서버를 해킹하거나, 웹사이트 또는 게시된 정보의 일부분 또는 전체를 임의로 변경하는 행위
           <br/>
           6. 서비스에 위해를 가하거나 고의로 방해하는 행위
           <br/>
           7. 본 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제 3자에게 제공하는 행위
           <br/>
           8. 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송,게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
           <br/>
           9. 모욕적이거나 개인신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
           <br/>
           10. 다른 이용자를 희롱 또는 위협하거나, 특정 이용자에게 지속적으로 고통 또는 불편을 주는 행위
           <br/>
           11. 회사의 승인을 받지 않고 다른 사용자의 개인정보를 수집 또는 저장하는 행위
           <br/>
           12. 범죄와 결부된다고 객관적으로 판단되는 행위
           <br/>
           13. 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위
           <br/>
           14. 기타 관계법령에 위배되는 행위제 
           <br/><br/>
           제 4 장 서비스의 이용제 12 조 (서비스 이용 시간)
           <br/>
           (1) 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다. 단, 회사는 시스템 정기점검, 증설 및 교체를 위해 회사가 정한 날이나 시간에 서비스를 일시 중단할 수 있으며, 예정되어 있는 작업으로 인한 서비스 일시중단은 미라클인공지능연구소를 통해 사전에 공지합니다.
           <br/>
           (2) 회사는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고없이 일시적으로 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 회사가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.
           <br/>
           (3) 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만 이 경우 그 사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.
           <br/>
           (4) 회사는 회사가 통제할 수 없는 사유로 인한 서비스중단의 경우(시스템관리자의 고의, 과실 없는 디스크장애, 시스템다운 등)에 사전통지가 불가능하며 타인(PC통신회사, 기간통신사업자 등)의 고의, 과실로 인한 시스템중단 등의 경우에는 통지하지 않습니다.
           <br/>
           (5) 회사는 서비스를 특정범위로 분할하여 각 범위별로 이용가능시간을 별도로 지정할 수 있습니다. 다만 이 경우 그 내용을 공지합니다.
           <br/><br/>
           제 13 조 (이용자ID 관리)
           <br/>
           (1) 이용자ID와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다.
           <br/>
           (2) 회사는 이용자 ID에 의하여 제반 이용자 관리업무를 수행 하므로 회원이 이용자 ID를 변경하고자 하는 경우 회사가 인정할 만한 사유가 없는 한 이용자 ID의 변경을 제한할 수 있습니다.
           <br/>
           (3) 이용고객이 등록한 이용자 ID 및 비밀번호에 의하여 발생되는 사용상의 과실 또는 제 3자에 의한 부정사용 등에 대한 모든 책임은 해당 이용 고객에게 있습니다.
           <br/><br/>
           제 14 조 (게시물의 관리)회사는 다음 각 호에 해당하는 게시물이나 자료를 사전통지 없이 삭제하거나 이동 또는 등록 거부를 할 수 있습니다.
           <br/>
           1. 다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우
           <br/>
           2. 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우
           <br/>
           3. 불법복제 또는 해킹을 조장하는 내용인 경우
           <br/>
           4. 영리를 목적으로 하는 광고일 경우
           <br/>
           5. 범죄와 결부된다고 객관적으로 인정되는 내용일 경우
           <br/>
           6. 다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우
           <br/>
           7. 회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우
           <br/>
           8. 기타 관계법령에 위배된다고 판단되는 경우제 
           <br/><br/>
           제 15 조 (게시물에 대한 저작권)
           <br/>
           (1) 회원이 서비스 화면 내에 게시한 게시물의 저작권은 게시한 회원에게 귀속됩니다. 또한 회사는 게시자의 동의 없이 게시물을 상업적으로 이용할 수 없습니다. 다만 비영리 목적인 경우는 그러하지 아니하며, 또한 서비스내의 게재권을 갖습니다.
           <br/>
           (2) 회원은 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.
           <br/>
           (3) 회사는 회원이 게시하거나 등록하는 서비스 내의 내용물, 게시 내용에 대해 제 14조 각 호에 해당된다고 판단되는 경우 사전통지 없이 삭제하거나 이동 또는 등록 거부할 수 있습니다.
           <br/><br/>
           제 16 조 (정보의 제공)(1) 회사는 회원에게 서비스 이용에 필요가 있다고 인정되는 각종 정보에 대해서 전자우편이나 서신우편 등의 방법으로 회원에게 제공할 수 있습니다.
           <br/>
           (2) 회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의 동의 하에 추가적인 개인 정보를 요구할 수 있습니다.
           <br/><br/>
           제 17 조 (광고게재 및 광고주와의 거래)<br/>
           (1) 회사가 회원에게 서비스를 제공할 수 있는 서비스 투자기반의 일부는 광고게재를 통한 수익으로부터 나옵니다. 회원은 서비스 이용 시 노출되는 광고게재에 대해 동의합니다.
           <br/>
           (2) 회사는 서비스상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다.<br/>
           <br/><br/>
           제 5 장 계약 해지 및 이용 제한<br/>
           제 18 조 (계약 변경 및 해지)회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시 회원탈퇴를 처리합니다.
           <br/>
           제 19 조 (서비스 이용제한)
           <br/>
           (1) 회사는 회원이 서비스 이용내용에 있어서 본 약관 제 11조 내용을 위반하거나, 다음 각 호에 해당하는 경우 서비스 이용을 제한할 수 있습니다.
           <br/>1. 미풍양속을 저해하는 비속한 ID 및 별명 사용
           <br/>2. 타 이용자에게 심한 모욕을 주거나, 서비스 이용을 방해한 경우
           <br/>3. 기타 정상적인 서비스 운영에 방해가 될 경우
           <br/>4. 정보통신 윤리위원회 등 관련 공공기관의 시정 요구가 있는 경우
           <br/>5. 3개월 이상 서비스를 이용한 적이 없는 경우
           <br/>6. 마이홈에 인덱스 파일없이 자료만 올려 놓고 파일 자료실 전용으로 이용하는 경우
           <br/>7. 불법 홈페이지인 경우-. 상용소프트웨어나 크랙파일을 올린 경우-. 정보통신윤리 위원회의 심의 세칙 제 7조에 어긋나는 음란물을 게재한 경우-. 반국가적 행위의 수행을 목적으로 하는 내용을 포함한 경우-. 저작권이 있는 글을 무단 복제하거나 mp3를 홈계정에 올린 경우
           <br/>(2) 상기 이용제한 규정에 따라 서비스를 이용하는 회원에게 서비스 이용에 대하여 별도 공지 없이 서비스 이용의 일시정지, 초기화, 이용계약 해지 등을 불량이용자 처리규정에 따라 취할 수 있습니다.
           <br/><br/>
           제 6 장 손해배상 및 기타사항
           <br/>제 20 조 (손해배상)회사는 서비스에서 무료로 제공하는 서비스의 이용과 관련하여 개인정보보호정책에서 정하는 내용에 해당하지 않는 사항에 대하여는 어떠한 손해도 책임을 지지 않습니다.
           <br/>제 21 조 (면책조항)
           <br/>(1) 회사는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.
           <br/>(2) 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
           <br/>(3) 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.
           <br/>(4) 회사는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.
           <br/>(5) 회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.
           <br/>(6) 회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.
           <br/>(7) 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한 회사는 회원이 서비스를 이용하며 타 회원으로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.
           <br/>(8) 회사는 회원이 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다.
           <br/>(9) 회사는 이용자 상호간 및 이용자와 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.
           <br/>(10) 회사에서 회원에게 무료로 제공하는 서비스의 이용과 관련해서는 어떠한 손해도 책임을 지지 않습니다.
           <br/>제 22 조 (재판권 및 준거법)
           <br/>(1) 이 약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에 따릅니다.
           <br/>(2) 회사의 정액 서비스 회원 및 기타 유료 서비스 이용 회원의 경우 회사가 별도로 정한 약관 및 정책에 따릅니다.
           <br/>(3) 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.
           <br/>부칙(시행일) 본 약관은 2023년 월 일부터 적용됩니다.
            </p>
           </div>
           <div className='agreebox'>
               <input type="checkbox" className="chk" onChange={(e)=> setUseCheck(e.target.checked)}/> 위 이용약관에 동의합니다
            </div>    
        </div>
       <div className='registerconfirm-Btn'>
         <button style={{marginRight:"1.3rem"}}  
          onClick={() => {
            navigate("/");
          }}>취 소</button>
         <button onClick={()=>{
          registerHandleForm();
         }}>확 인</button>
         </div>
        </form>
      </div>
    </div>
  )
 }


export default Registerconfirm;