import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter, faYoutube
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import { Link } from "react-router-dom";
import MyA from "../shared/MyA";

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

function Footer() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
     {windowSize.innerWidth > 900 ?
      <div className="footer-container">
        <div className="footer-wrap">
            <ul className="footer-ul">
                  <li className="footer-li"><MyA to="/privacypolicy"><span>개인정보취급방침</span></MyA></li>
                  <li className="footer-li"><MyA to="/emailprivacy"><span>이메일무단수집거부</span></MyA></li>
                  <li className="footer-li"><a href="mailto:veron48@naver.com"><span>문의메일</span></a></li>
                  <li className="footer-lilast"><MyA to="/location"><span>약도</span></MyA></li>
                  <li className="footer-icon"><a href="http://www.facebook.com/miracleailab"><FontAwesomeIcon icon={faFacebook} className="ficon" /></a></li>
                  <li className="footer-icon"><a href="http://www.instagram.com/miracleailab"><FontAwesomeIcon icon={faInstagram} className="ficon" /></a></li>
                  <li className="footer-icon"><a href="http://www.youtube.com/@miracleailab"><FontAwesomeIcon icon={faYoutube} className="ficon" /></a></li>
                  <li className="footer-icon"><a href="https://tv.naver.com/miracleailab"><img src={require('../image/naver.png')} style={{width: 16, marginBottom:4,marginLeft:4}}/></a></li>
                  <li className="footer-icon"><a href="https://ai.miracle-dream.net/m/"><img src={require('../image/tistory.png')} style={{width: 16, marginBottom:4,marginLeft:9}}/></a></li>
            </ul>    
            <br/>
            <p>         
            <span>미라클인공지능연구소<small>  ㅣ   </small>사업자 등록번호 : 526-22-01371<small>  ㅣ   </small>대표자 : 차석호</span><small>  ㅣ   </small>주소 : 부산광역시 부산진구 동천로 109, 904호 (삼한골든게이트)<br/>
                <span>TEL : 010-8496-6612 </span><small>  ㅣ  </small> E-mail : veron48@naver.com            
            </p>
            <div className="credit">
              COPYRIGHT(C) MiracleAILab. All rights reserved
            </div>
          </div>
      </div>
      :
      <div className="footer-wrap-mobile">
        <ul  className="footer-top">
                <li className="footer-icon-mobile"><a href="http://www.facebook.com/miracleailab"><FontAwesomeIcon icon={faFacebook} className="ficon" size="2x" /></a></li>
                  <li className="footer-icon-mobile"><a href="http://www.instagram.com/miracleailab"><FontAwesomeIcon icon={faInstagram} className="ficon" size="2x" /></a></li>
                  <li className="footer-icon-mobile"><a href="http://www.youtube.com/@miracleailab"><FontAwesomeIcon icon={faYoutube} className="ficon" size="2x" /></a></li>
                  <li className="footer-icon-mobile"><a href="https://tv.naver.com/miracleailab"><img src={require('../image/naver.png')} className="ficonImg"/></a></li>
                  <li className="footer-icon-mobile"><a href="https://ai.miracle-dream.net/m/"><img src={require('../image/tistory.png')} className="ficonImgT"/></a></li>
          </ul>  
           {/*<ul className="footer-middle" style={{display: "flex", width: "26rem", margin: "0 auto", marginBottom: "0.6rem"}}>
                <li className="footer-li"><MyA to="#"><span>개인정보취급방침</span></MyA></li>
                <li className="footer-li-mobile"><MyA to="#"><span>이메일무단수집거부</span></MyA></li>
                <li className="footer-li"><MyA to="#"><span>문의메일</span></MyA></li> 
          </ul>    */}
          <p>         
            <span>미라클인공지능연구소</span> <small>  ㅣ  </small><span>대표자 : 차석호 </span>
            <br/><span>사업자 등록번호 : 526-22-01371</span>
            <br/><span>주소 : 부산광역시 부산진구 동천로 109, 904호 (삼한골든게이트)</span>
            {/* <br/>(남천동, 헤리티지 남천) */}
            <br/><span>TEL : 010-8496-6612 </span><small>  ㅣ  </small> E-mail : veron48@naver.com            
          </p>
          <div className="credit">
            COPYRIGHT(C) MiracleAILab. All rights reserved
          </div>
       </div>
      } 
      </>
  );
}

export default Footer;
