import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import Menulists from "../../data/Menulist";
import SubMenubar from "../layout/SubMenubar";
import SubImgbar from "../layout/SubImgbar";
import "./QNABoard.css";
import MyA from "../shared/MyA.js";
import Search from "../shared/Search.js";
import { FiLock } from "react-icons/fi";
import useResize from "../../hooks/useResize.js";
import { useGetUserId } from "../../hooks/useApi.js";
import Pagination from "../shared/Pagination.js";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";
function QNABoard() {
  const location = useLocation();
  const base_url = "https://admin.miracledream.net/board/qnaboards";
  const sub_menubar = Menulists.find((m) => m.url == location.pathname);
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );
  const [notices, setNotices] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [baseurl, setbaseurl] = useState(base_url);
  const [currentpage, setcurrentpage] = useState(1);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const perpage = 20;
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const windowWidth = useResize();
  const [userId, isAdmin, writer] = useGetUserId();
  function changeurl(event) {
    setbaseurl(event);
  }

  async function getQnaNotices() {
    setLoading(true);
    const { data } = await axios.get(baseurl);
    setNotices(data.results);
    setTotalCount(data.count);
    setNext(data.next);
    setPrevious(data.previous);
    setLoading(false);
  }
  useEffect(() => {
    getQnaNotices(baseurl);
  }, [baseurl]);
  const lastPage = Math.ceil(totalcount / perpage);


  // const replyHandler = (noticeId, childId) => {
  //   navigate(`detail/${noticeId}&${childId}`);
  // };
  const qnaArticles = [];
  const qnaChildArticle =[];
  for (let i = 0; i < notices.length; i++) {
    qnaArticles.push({
      title: notices[i].title,
      writer: notices[i].user?.name,
      date: notices[i].created_at,
      id: notices[i].id,
      private: notices[i].is_private,
      child: notices[i].child ? "have" : "null",
      writerId: notices[i].user?.id,
    });
    if (notices[i].child && notices[i].child.length > 0) {
      qnaArticles.push({
        reply: "reply",
        title: notices[i].child[0].title,
        writer: notices[i].child[0].user.name,
        date: notices[i].child[0].created_at,
        id: notices[i].child[0].id,
        private: notices[i].is_private,
        writerId: notices[i].user.id,
      });
      qnaChildArticle.push({
        title: notices[i].child[0].title,
      })
    }
  }
  const qnaArticlesCount = qnaArticles.length;
  const childArticleCount = qnaChildArticle.length

  //console.log("qnaArticle"+qnaArticles[0])
  //   const privateReplyHandler = (noticeId, childId, noticeUserId) => {
  //     try {
  //       if (noticeUserId === userId || isAdmin) {
  //         navigate(`detail/${noticeId}&${childId}`);
  //       } else {
  //         window.alert("비밀글입니다.");
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  // console.log(userId)
  const privateHandler = (writerId, qnaId) => {
    try {
      if (writerId === userId || isAdmin) {
        navigate(`detail/${qnaId}`);
      } else {
        window.alert("비밀글입니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="qnaboard-container">
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="qnaboard-wrap">
          <div className="qnaboardwrite">
            <MyA to="/qnaboard/insert">
              <button className="boardwrite-btn" type="button">
                글쓰기
              </button>
            </MyA>
          </div>
          <table className="qnaboard">
            <thead>
              <tr>
                {/* <td>
                  <strong>번호</strong>
                </td> */}
                <td>
                  <strong>제목</strong>
                </td>
                <td>
                  <strong>작성자</strong>
                </td>
                <td>
                  <strong>등록일</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={5}>Loading...</td>
                </tr>
              ) : (
                <>
                  {qnaArticles && qnaArticles.length > 0 ? (
                    <>
                      {qnaArticles.map((qnaArticle, key) => {
                        return (
                          <tr key={key}>
                            {/* <td>  {totalcount - perpage * (currentpage - 1) - key+childArticleCount}</td> */}
                            {qnaArticle.reply && qnaArticle.reply.length > 0 ? (
                              <>
                                {qnaArticle.private ? (
                                  <td>
                                    <span
                                      onClick={() =>
                                        privateHandler(
                                          qnaArticle.writerId,
                                          qnaArticle.id
                                        )
                                      }
                                    >
                                      <MdOutlineSubdirectoryArrowRight />
                                      <span style={{ color: "rgb(16, 56,172)", marginRight: 10, marginLeft: 10}}>
                                        답변
                                      </span>
                                      <FiLock /> 비밀글입니다
                                    </span>
                                    {/* {isAdmin ? (
                                  <MyA
                                    to={`/qnaboard/insert?id=${qnaArticle.id}&isPrivate=true`}
                                  >
                                    {qnaArticle.child == null && (
                                      <span className="qna-reply-button">
                                        답글달기
                                      </span>
                                    )}
                                  </MyA>
                                ) : (
                                  <></>
                                )} */}
                                  </td>
                                ) : (
                                  <td>
                                    <MyA to={`detail/${qnaArticle.id}`}>
                                      <MdOutlineSubdirectoryArrowRight />
                                      <span style={{ color: "rgb(16, 56,172)", marginRight: 10, marginLeft: 10}}>
                                        답변
                                      </span>
                                      <span>{qnaArticle.title}</span>
                                    </MyA>
                                    {/* {isAdmin ? (
                                  <>
                                    <MyA
                                      to={`/qnaboard/insert?id=${qnaArticle.id}`}
                                    >
                                      {qnaArticle.child == null && (
                                        <span className="qna-reply-button">
                                          답글달기
                                        </span>
                                      )}
                                    </MyA>
                                  </>
                                ) : (
                                  <></>
                                )} */}
                                  </td>
                                )}
                                <td>{qnaArticle.writer}</td>
                                <td>{qnaArticle.date.slice(2, 10)}</td>
                              </>
                            ) : (
                              <>
                                {qnaArticle.private ? (
                                  <td>
                                    <span
                                      onClick={() =>
                                        privateHandler(
                                          qnaArticle.writerId,
                                          qnaArticle.id
                                        )
                                      }
                                    >
                                      <FiLock /> 비밀글입니다
                                    </span>
                                    {isAdmin ? (
                                      <MyA
                                        to={`/qnaboard/insert?id=${qnaArticle.id}&isPrivate=true`}
                                      >
                                        {qnaArticle.child == "null" && (
                                          <span className="qna-reply-button">
                                            답글달기
                                          </span>
                                        )}
                                      </MyA>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                ) : (
                                  <td>
                                    <MyA to={`detail/${qnaArticle.id}`}>
                                      <span>{qnaArticle.title}</span>
                                    </MyA>
                                    {isAdmin ? (
                                      <>
                                        <MyA
                                          to={`/qnaboard/insert?id=${qnaArticle.id}`}
                                        >
                                          {qnaArticle.child == "null" && (
                                            <span className="qna-reply-button">
                                              답글달기
                                            </span>
                                          )}
                                        </MyA>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                )}
                                <td>{qnaArticle.writer}</td>
                                <td>{qnaArticle.date.slice(2, 10)}</td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center">등록된 글이 없습니다</td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
            {/* <tr>
                 <td></td>
               <td>{qnaArticle.title}</td>
               <td>{qnaArticle.writer}</td>
                 <td>{qnaArticle.date?.slice(2,10)}</td>
               </tr> */}
          </table>
          {windowWidth > 768 ? (
            <>
              <div className="boardBottom">
                <Pagination
                  notices={notices}
                  previous={previous}
                  base_url={base_url}
                  search={search}
                  changeurl={changeurl}
                  setcurrentpage={setcurrentpage}
                  currentpage={currentpage}
                  totalcount={totalcount}
                  perpage={perpage}
                  next={next}
                />
                <Search
                  baseurl={base_url}
                  onChange={setSearch}
                  setNotices={setNotices}
                  setTotalCount={setTotalCount}
                  setNext={setNext}
                  setPrevious={setPrevious}
                />
              </div>
            </>
          ) : (
            <>
              <div className="boardBottom">
                <div className="d-colum w-100">
                  <div className="bottompagination">
                    <Pagination
                      notices={notices}
                      previous={previous}
                      base_url={base_url}
                      changeurl={changeurl}
                      setcurrentpage={setcurrentpage}
                      currentpage={currentpage}
                      totalcount={totalcount}
                      perpage={perpage}
                      next={next}
                    />
                  </div>
                  <div className="searchSBox">
                    <Search
                      baseurl={baseurl}
                      setNotices={setNotices}
                      setTotalCount={setTotalCount}
                      setNext={setNext}
                      setPrevious={setPrevious}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ContentLayout>
    </div>
  );
}

export default QNABoard;
// (
//   <>
//     {notices && notices.length>0 ? (
//       notices.map((notice, key) => {
// const privateHandler = () => {
//   try {
//     if (notice.user.id === userId || isAdmin) {
//       navigate(`detail/${notice.id}`);
//     } else {
//       window.alert("비밀글입니다.");
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

//         return (
//           <>
//             <tr key={key}>
//               <td>{totalcount - perpage * (currentpage - 1) - key}</td>
//               {notice.is_private ? (
//                   <td>
//                     <span onClick={privateHandler}>
//                       <FiLock /> 비밀글입니다
//                     </span>
//                     {isAdmin ? (
//                       <MyA
//                         to={`/qnaboard/insert?id=${notice.id}&isPrivate=true`}
//                       >
//                         {notice.child ==null &&  <span className="qna-reply-button">
//                           답글달기
//                         </span>}
//                       </MyA>
//                     ) : (
//                       <></>
//                     )}
//                   </td>
//                 ) : (
//                   <td>
//                     <MyA to={`detail/${notice.id}`}>
//                       <span>{notice.title}</span>
//                     </MyA>
//                     {isAdmin ? (
//                       <>
//                       <MyA
//                         to={`/qnaboard/insert?id=${notice.id}`}
//                       >
//                          {notice.child ==null &&  <span className="qna-reply-button">
//                           답글달기
//                         </span>}
//                       </MyA>
//                       </>
//                     ) : (
//                       <></>
//                     )}
//                   </td>
//                 )}
//                 <td>{notice.user.name}</td>
//                 <td>{notice.created_at.slice(2, 10)}</td>
//             </tr>
//             <tr>
//               {notice.child !==null &&
//               (<>
//               <td></td>
//               <td><MdOutlineSubdirectoryArrowRight/> <span style={{color:"grey"}}>답변</span>
//               {notice.child[0].is_private ?
//                 <span onClick={()=>privateReplyHandler(notice.id, notice.child[0].id, notice.user.id)}> <FiLock /> 비밀글입니다</span>
//               :<span onClick={()=> replyHandler(notice.id, notice.child[0].id)}> {notice.child[0].title}</span>
//               }</td>
//               <td>{notice.child[0].user.name}</td>
//               <td>{notice.child[0].created_at.slice(2, 10)}</td>
//               </>)}
//             </tr>
//           </>
//         );
//       })
//     ) : (
//       <tr>
//         <td colSpan={4}>등록된 글이 없습니다</td>
//       </tr>
//     )}
//   </>
// )
