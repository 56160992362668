import React from 'react'
import "./PrivacyPolicy.css";
import SubImgbar from "../layout/SubImgbar";
import ContentLayout from "../shared/ContentLayout";
const PrivacyPolicy = () => {
  return (
    <div className='privacy-container'>
      <SubImgbar />
      <ContentLayout>
      <div className='privacy-wrap'>
          <h4>개인정보 수집 이용</h4>
          <h6>:::: Miracle인공지능연구소 개인정보취급방침 ::::</h6>
          <br/>
          <p>
          
           <br/>
           개인정보의 수집목적 및 이용목적
           <br/><br/>
          ① 미라클인공지능연구소는 회원님께 최대한으로 최적화되고 맞춤화된 서비스를 제공하기 위하여 다음과 같은 목적으로 개인정보를 수집하고 있습니다. 성명, 아이디, 비밀번호 : 회원제 서비스 이용에 따른 본인 식별 절차에 이용 이메일주소, 이메일 수신여부, 전화번호 : 고지사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사소통 경로의 확보, 새로운 서비스이나 행사 정보의 안내 주소, 전화번호 : 개인맞춤 서비스를 제공하기 위한 자료
           <br/>
          ② 단, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다. 개인정보의 수집범위 미라클인공지능연구소는 별도의 회원가입 절차 없이 대부분의 콘텐츠에 자유롭게 접근할 수 있습니다. 의 회원제 서비스를 이용하시고자 할 경우 다음의 정보를 입력해주셔야 하며 선택항목을 입력하시지 않았다 하여 서비스 이용에 제한은 없습니다.
          <br/><br/>
          회원 가입시 수집하는 개인정보의 범위
          <br/><br/>
          필수항목 : 희망 ID, 비밀번호, 성명, 전화번호, 이메일주소, 이메일 수신 여부
          <br/><br/>
          쿠키에 의한 개인정보 수집
          <br/><br/>
          ① 쿠키(cookie)란?<br/>
          미라클 인공자능 연구소는 귀하에 대한 정보를 저장하고 수시로 찾아내는 쿠키(cookie)를 사용합니다. 쿠키는 웹사이트가 귀하의 컴퓨터 브라우저로 전송하는 소량의 정보입니다. 귀하께서 웹사이트에 접속을 하면 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다. 쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는 않습니다. 또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저 상단의 도구, 인터넷옵션 탭(option tab)에서 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.
          ② 미라클인공지능연구소의 쿠키(cookie) 운용 미라클인공지능연구소는 이용자의 편의를 위하여 쿠키를 운영합니다. 이 쿠키를 통해 수집하는 정보는 회원 ID에 한하며, 그 외의 다른 정보는 수집하지 않습니다. 이 쿠키(cookie)를 통해 수집한 회원 ID는 다음의 목적을 위해 사용됩니다. 개인의 관심 분야에 따라 차별화된 정보를 제공 회원과 비회원의 접속빈도 또는 머문시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 타겟(target) 서비스에 활용 구입한 논문지들에 대한 정보와 관심있게 둘러본 품목들에 대한 자취를 추적하여 다음번 방문 때 개인 맞춤 서비스를 제공 회원들의 습관을 분석하여 서비스 개편 등의 척도 게시판 글 등록쿠키는 브라우저의 종료시나 로그아웃시 만료됩니다.
          <br/><br/>
          게시물
          <br/><br/>
          ① 미라클인공지믕연구소 홈페이지는 회원의 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다. 그러나 다음의 경우는 그렇지 아니합니다. 스팸(spam)성 게시물 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 글 동의 없는 타인의 신상공개, 제 3자의 저작권 등 권리를 침해하는 내용, 기타 게시판 주제와 다른 내용의 게시물 비밀번호 힌트용 질문과 답변 : 비밀번호를 잊은 경우의 신속한 처리를 위한 내용 다른 주제의 게시판으로 이동 가능한 내용일 경우 해당 게시물에 이동 경로를 밝혀 오해가 없도록 하고 있습니다. 그 외의 경우 명시적 또는 개별적인 경고 후 삭제 조치할 수 있습니다.
          ② 근본적으로 게시물에 관련된 제반 권리와 책임은 작성자 개인에게 있습니다. 또한 게시물을 통해 자발적으로 공개된 정보는 보호받기 어려우므로 정보 공개 전에 심사 숙고하시기 바랍니다.
          <br/><br/>
          개인정보의 보유기간 및 이용기간
          <br/><br/>
          ① 귀하의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기됩니다. 단, 상법 등 관련법령의 규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다. 회원가입정보의 경우, 회원가입을 탈퇴하거나 회원에서 제명된 경우 등 일정한 사전에 보유목적, 기간 및 보유하는 개인정보 항목을 명시하여 동의를 구합니다. 계약 또는 청약철회 등에 관한 기록 : 5년 보관대금결제 및 재화 등의 공급에 관한 기록 : 5년 보관 회원의 불만 또는 분쟁처리에 관한 기록 : 3년 보관<br/>
          ② 귀하의 동의를 받아 보유하고 있는 거래정보 등을 귀하께서 열람을 요구하는 경우 미라클인공지눙연구소눈 지체없이 그 열람,확인 할 수 있도록 조치합니다. 미라클인공지능연구소는 이용자의 개인정보를 안전하게 관리하기 위하여 최선을 다하며, 정보통신망법 및 개인정보보호법에서 요구하는 수준 이상으로 개인정보를 보호하고 있습니다.</p>
           </div>
           </ContentLayout>
    </div>
  )
}

export default PrivacyPolicy
