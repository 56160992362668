import axios from "axios";
import React, { useEffect, useState , useCallback} from "react";
import "./Login.css";
import SubImgbar from "../layout/SubImgbar"
import MyA from "../shared/MyA";
import { useLocation, useNavigate } from "react-router-dom";
import {axiosInstance, setAuthorization} from "../../axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {RedirectAtom, TokenAtom} from "../../recoil/User";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Login = () => {
  const [id, setId] = useState("");
  const [passwd, setPasswd] = useState("");
  const [idEmailValid, setIdEmailValid] = useState(false);
  const [pwValid, setPwValid] = useState(false);
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  // const [accessToken, setAccessToken] = useRecoilState(TokenAtom);
  const location = useLocation();
  // const isFromRegisterConfirm = location.state && location.state.from && location.state.from.pathname === '/registerconfirm';
  const setAccessToken = useSetRecoilState(TokenAtom);
  const RedirectName = useRecoilValue(RedirectAtom);

  console.log(RedirectName)
  const handleForm = async (e) => {
    e.preventDefault();
    await axios
      .post("https://admin.miracledream.net/api/user/login/",
        { email: id, password: passwd })
      .then((response) => {
        if(response.data?.access){
          localStorage.setItem('access_token', response.data.access);
          localStorage.setItem('refresh_token', response.data.refresh);
          setAccessToken(response.data.access);
        }
    
        if(response.status ===200){
            setAuthorization();
            // if(RedirectName ==='/registerconfirm' ||RedirectName === '/register' ||RedirectName === '/login'){
            //   navigate("/");
            // }else{
            //   navigate(RedirectName);
           
            // };
            navigate("/")
        }

      }).catch((error)=>{

        console.log(error)
          if(error.request.status === 401){
            window.alert("아이디 비번을 다시 확인하시기 바랍니다.")
          }else{
            console.log(error)
          }
      });
  };


  useEffect(() => {

    // const token = localStorage.getItem('access_token');
    // if (token) {
    //   axios.post("https://admin.miracledream.net/api/user/verify/", {
    //     token: token
    //   }).then((response) => {

    //     if (response.status === 200) {
    //       navigate("/");
    //       alert("이미 로그인 되어있습니다.");
    //     }

    //   }).catch((error) => {
    //     console.log(error);
    //   })
    // }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  // const handleId = (e) =>{
  //   setId(e.target.value);
  //   const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  //   if(regex.test(id)){
  //     setIdEmailValid(true);
  //   }else{
  //     setIdEmailValid(false);
  //   }
  // }

  // const handlePw = (e) =>{
  //   setPasswd(e.target.value);
  //   const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
  //   if(regex.test(passwd)){
  //     setPwValid(true)
  //   }else{
  //     setPwValid(false);
  //   }
  // }

  useEffect(() => {
    //  if(idEmailValid && pwValid){
    //   setNotAllow(false);
    //   return;
    //  }
    //  setNotAllow(true);
  }, [idEmailValid, pwValid])


  return (
    <div className="login-wrap">
      <SubImgbar />
      <div className="login-container">
        <h2>로그인</h2>
        <p>미라클 인공지능연구소에 오신것을 환영합니다.</p>
        <p>로그인을 위해 아이디와 비밀번호를 입력하세요.</p>
        <div className="login-box">
          <form method="post" onSubmit={handleForm}>
            <div className="login-control">
              <input
                type="text"
                value={id}
                onChange={(e) => setId(e.target.value)}
                //onChange={handleId}
                placeholder="아이디"
                required
              />
              {/* <div className="errorMessage">
                {
                !idEmailValid && id.length>0 && (
                  <div>이메일형식으로 입력해주세요</div>
                )
                }
              </div> */}
            </div>
            <div className="login-control">
              <input
                type="password"
                value={passwd}
                //onChange={handlePw}
                onChange={(e) => setPasswd(e.target.value)}
                placeholder="비밀번호"
                required
              />
              {/* <div className="errorMessage">
                {
                  !pwValid && passwd.length>0 && (
                    <div>영문,숫자,특수문자 포함 8자이상 20자이하 입력해주세요</div>
                  )
                }
              </div> */}
            </div>
            <div>
              <input type="submit" className="loginBtn" value="로그인" />
              {/* disabled={notAllow} */}
            </div>
            {windowSize.innerWidth > 425 ?
              <div className="id-container">
                <MyA to="/findId"><label>아이디 찾기</label></MyA>
                <small>  ㅣ  </small>
                <MyA to="/findId"><label>비밀번호 찾기</label></MyA>
                <small>  ㅣ  </small>
                <MyA to="/registerconfirm"><label>회원가입</label></MyA>
              </div> :
              <div className="id-container">
                <MyA to="/findId"><label>아이디 <span>&#183;</span> 비밀번호 찾기</label></MyA>ㅣ
                <MyA to="/registerconfirm"><label>회원가입</label></MyA>
              </div>
            }
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
