import React from 'react';
import "./ContentLayout.css";
const ContentLayout = ({children}) => {
  return (
    <div className='allContentLayout' >
        {children}
    </div>
  )
}

export default ContentLayout