import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import Menulists from "../../data/Menulist";
import SubMenubar from "../layout/SubMenubar";
import SubImgbar from "../layout/SubImgbar";
import MyA from "../shared/MyA.js";
import QuillEditor from "../shared/QuillEditor.js";
import "./QNABoardInsert.css";
import { useFormPostData, useGetUserId, usePostData } from "../../hooks/useApi.js";
import {axiosInstance} from "../../axios.js";

function ReferenceInsert() {
  const location = useLocation();
  const sub_menubar = Menulists.find(
    (m) => m.url == `/${location.pathname.split("/")[1]}`
  );
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [userId, isAdmin, writer] = useGetUserId();
  const [file, setFile] = useState(null);
  const postFormData = useFormPostData("/board/materials/", "/reference");
  const postData = usePostData("/board/materials/", "/reference");

  const category = async () => {
    const { data } = await axiosInstance.get("/board/material-categories/");
    setCategoryList(data);
  };
  const handleForm = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("user", parseInt(userId));
    formData.append("category", selectedCategory);
    formData.append("title", title);
    formData.append("content", content);
    formData.append("upfile", file);

    const info = {
      user: parseInt(userId),
      category: selectedCategory,
      title: title,
      username: writer,
      content: content,
      upfile: file,
    };
    if (title.trim() === "") {
      window.alert("제목을 입력해주세요");
      return;
    }
    const processedContent = content
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "")
      .trim();
    if (content === "<p><br></p>" || processedContent === "") {
      window.alert("내용을 입력해주세요.");
      return;
    }
  
    if (!file|| typeof file === "string" ) {
      await postData(info);
    } else {
      await postFormData(formData);
    }


  }

  const handleText = (value) => {
    setContent(value);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  useEffect(() => {
    category();
  }, []);

  return (
    <div className="boardInsert">
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="boardInsert-wrap">
          <form method="post" onSubmit={handleForm}>
            <table className="boarderInsertTable">
              <thead>
                <tr>
                  <td>
                    <strong>제목</strong>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="title"
                      placeholder="제목을 입력해주세요"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <strong style={{ marginLeft: 10 }}>{writer}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>카테고리</strong>
                  </td>
                  <td>
                    <select
                      style={{ marginLeft: 10 }}
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                    >
                      {categoryList.map((cate, key) => {
                        return (
                          <option key={cate.id} value={cate.id}>
                            {cate.name}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>내용</strong>
                  </td>
                  <td className="QnaBoardcontent">
                    {/* <EditorToolbar/> */}
                    <QuillEditor value={content} onChange={handleText} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>파일</strong>
                  </td>
                  <td>
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="QNABoardBtnGrp">
              <button type="submit">저장</button>
              <MyA to="/reference">
                <button type="button" className="list">
                  목록
                </button>
              </MyA>
              {/* <MyA to="/qnaboardDel">
                <button type="button" className="del" >
                  삭제
                </button>
              </MyA> */}
            </div>
          </form>
        </div>
      </ContentLayout>
    </div>
  );
}

export default ReferenceInsert;
