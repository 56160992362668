import React from "react";
import MyA from "../shared/MyA";
import "./SubMenbar.css";

const SubMenubar = ({ submenulists,subtitle }) => {
  return (
    <>
    <div className="bar">
      { submenulists.map((m, key) => {
            return (
              <MyA to={`${m.url}`} key={key}>
                <span>{m.title}</span>
              </MyA>
            );
          })
       }
    </div>
   <div className='contentSubtitle'>
     <h2 className='contentboard'>{subtitle}</h2>
  </div>
  </>
  );
};

export default SubMenubar;
