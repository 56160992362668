import axios from 'axios'

const base = "https://admin.miracledream.net/";

export const axiosInstance = axios.create({
	baseURL: base,
	headers: {
		Authorization: localStorage.getItem('access_token')
			? 'JWT ' + localStorage.getItem('access_token')
			: null,
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
});


export const axiosFormInstance = axios.create({
	baseURL: base,
	headers: {
		Authorization: localStorage.getItem("access_token")
		  ? "JWT " + localStorage.getItem("access_token")
		  : null,
		"Content-Type": "multipart/form-data",
		accept: 'application/json',
	},
});

export const setAuthorization = () => {
	const token = localStorage.getItem("access_token")
	axiosInstance.interceptors.request.use(function (config) {
		config.headers.Authorization = `Bearer ${token}`;
		return config;
	  });

	  axiosFormInstance.interceptors.request.use(function (config) {
		config.headers.Authorization = `Bearer ${token}`;
		return config;
	  });
  };