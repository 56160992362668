import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import Menulists from "../../data/Menulist";
import SubMenubar from "../layout/SubMenubar";
import SubImgbar from "../layout/SubImgbar";
import MyA from "../shared/MyA.js";
import ImageResize from "quill-image-resize";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./QNABoardInsert.css";
import jwtDecode from "jwt-decode";
import {axiosInstance} from "../../axios.js";
import QuillEditor from "../shared/QuillEditor.js";
import { useGetUserId, usePostData } from "../../hooks/useApi.js";

function QNABoardInsert() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const isPrivate = searchParams.get("isPrivate");


  const sub_menubar = Menulists.find(
    (m) => m.url == `/${location.pathname.split("/")[1]}`
  );
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isChecked, setIsChecked] = useState(isPrivate? isPrivate=== "true": false);

  const postData = usePostData("/board/qnaboards/", "/qnaboard");
  const [userId, isAdmin, writer] = useGetUserId();
  const handleForm = async (e) => {
    e.preventDefault();
  
    
    if (title.trim() === "") {
      window.alert("제목을 입력해주세요");
      return;
    }
    const processedContent = content
    .replace(/<p>/g, "")
    .replace(/<\/p>/g, "")
    .trim();
  if (content === "<p><br></p>" || processedContent === "") {
    window.alert("내용을 입력해주세요.");
    return;
  }
  if(id){
    const info = {
      user: parseInt(userId),
      title: title,
      content: content,
      is_private: isChecked,
      parent: parseInt(id),
    };
    await postData(info);
   }else{
    const info = {
      user: parseInt(userId),
      title: title,
      content: content,
      is_private: isChecked,
    }
    await postData(info);

   }

  };
  // const sub_menubar = Menulists.find((m)=>m.subUrl == location.pathname);
  //  const sub_menulists = Menulists.filter((ml)=>ml.mainMenu ==sub_menubar.mainMenu);
  // console.log(sub_menubar)

  // const [content, setContent] = useState([]);

  // useEffect(() => {
  //   getContent();
  // }, [content]);

  //const navigate = useNavigate();;

  const handleText = (value) => {
    setContent(value);

  };
  return (
    <div className="boardInsert">
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="boardInsert-wrap">
          <form method="post" onSubmit={handleForm}>
            <table className="boarderInsertTable">
              <thead>
                <tr>
                  <td>
                    <strong>제목</strong>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="title"
                      placeholder="제목을 입력해주세요"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>작성자</strong>
                  </td>
                  <td>
                    <strong style={{ marginLeft: 10 }}>{writer}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>내용</strong>
                  </td>
                  <td className="QnaBoardcontent">
                    <QuillEditor value={content} onChange={handleText} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>비밀글</strong>
                  </td>
                  <td className="privateCheckbox">
                   {isPrivate? <input
                      type="checkbox"
                      checked={isChecked}
                      readOnly
                    />:<input
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />} 
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="QNABoardBtnGrp">
              <button type="submit">저장</button>
              <MyA to="/qnaboard">
                <button type="button" className="list">
                  목록
                </button>
              </MyA>
            </div>
          </form>
        </div>
      </ContentLayout>
    </div>
  );
}

export default QNABoardInsert;
