import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faClipboard } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { FiLock , FiUser, FiClipboard} from "react-icons/fi";
import "./Mypage.css";
import SubImgbar from "../layout/SubImgbar";
import ContentLayout from "../shared/ContentLayout";
import { axiosInstance } from "../../axios";
import { useAllGetData } from "../../hooks/useApi";
function Mypage() {
  const navigate = useNavigate();
  // async function getMypageData(){
  //   const {data} = await axiosInstance.get('/api/user/mypage/');
  //   setMypateData(data)
  // }
  //  console.log(data[0])
  // useEffect(()=>{
  //   getMypageData();
  // },[])
  const [data, boardUserId, getData] = useAllGetData("/api/user/mypage");  useEffect(()=>{
    getData();
  },[])
  // setMypateData(data[0]);
  return (
    <div className="mypage-container">
      <SubImgbar />
      <ContentLayout>
        <div className="mypage-wrap">
          <div className="contentMypageSubtitle">
            <h2 className="contentMypageboard">마이페이지</h2>
          </div>
          <h4>기본정보</h4>
          {data && (
            <table>
              <thead>
                <tr>
                  <td>아이디</td>
                  <td>{data[0].email}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>비밀번호</td>
                  <td>
                    <span>**********</span>
                  </td>
                </tr>
                <tr>
                  <td>이름</td>
                  <td>{data[0].name}</td>
                </tr>
                <tr>
                  <td>휴대전화</td>
                  <td>{data[0].phone_num}</td>
                </tr>
                <tr>
                  <td>이메일 수신여부</td>
                  <td>{data[0].isEmailCheck === "true" ? "수신함" : "수신안함"}</td>
                </tr>
              </tbody>
            </table>
          )}
          <div className="btn-container d-flex justify-content-between">
            <div></div>
            <div className="btn-wrap d-flex">
              <div className="mypage-icon" onClick={() => navigate("/profile")}>
              <FiUser
                  className="mypage-icon"
                />
                <p>회원정보수정</p>
              </div>
              <div
                className="mypage-icon"
                onClick={() => navigate("/mypage/secretchange")}
              >
                <FiLock
                  className="mypage-icon"
                />
                <p>비밀번호변경</p>
              </div>
              <div
                className="mypage-icon"
                onClick={() => navigate("/mypageboard")}
              >
                <FiClipboard
                  className="mypage-icon"
                />
                <p>게시물 관리</p>
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
    </div>
  );
}

export default Mypage;
