import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import SubImgbar from "../layout/SubImgbar.js";
import Pagination from "react-bootstrap/Pagination";
import "./MypageBoard.css";
import { FaPaperclip } from "react-icons/fa";
import { useAllGetData } from "../../hooks/useApi.js";
import MyA from "../shared/MyA.js";
import useResize from "../../hooks/useResize.js";
function MypageBoard() {
  const [MBdata, setMBdata]= useState([]);
  const [boards, setBoards] = useState([]);
  const [qnaBoards, setQnaBoards] = useState([]);
  const [replys, setReplys] = useState([]);
  const [data] = useAllGetData("/api/user/mypage");
 const [count, setCount] = useState(0);
 const [qncount, setQnCount]=useState(0);
 const [recount, setReCount]=useState(0);
 const [totalCount, setTotalCount]=useState(0);
 const windowWidth  = useResize();
  const navigate = useNavigate();
  useEffect(() => {
    if (data && data.length > 0) {
      setMBdata(data[0]);
      setBoards(data[0].board);
      setQnaBoards(data[0].qna_board);
      setReplys(data[0].board_reply)
      setCount(data[0].board.length);
      setReCount(data[0].board_reply.length);
      setQnCount(data[0].qna_board.length);
      setTotalCount(data[0].board.length+data[0].board_reply.length+data[0].qna_board.length)
    }
  }, [data]);
  console.log(MBdata)
  return (
    <div className="board-container">
      <SubImgbar />
      <ContentLayout>
        <div className="contentMypageSubtitle">
          <h2 className="contentMypageboard">게시물 관리</h2>
        </div>
        <div className="mypageBoard-wrap">
         {windowWidth>500 ?(<><table className="mypageBoard">
            <thead>
              <tr>
                <td>
                  <strong>번호</strong>
                </td>
                <td>
                  <strong>분류</strong>
                </td>
                <td>
                  <strong>제목</strong>
                </td>
                <td>
                  <strong>등록일</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {MBdata.lengthL>0 &&MBdata ? (
                <>
                {boards &&
                    boards.map((board, key) => {
                      return (
                        <tr>
                          <td>
                        {totalCount-key}
                          </td>
                          <td>게시판</td>
                          <td style={{ textAlign: "left" }} key={key}>
                            <MyA to={`/board/detail/${board.id}`}>{board.title}</MyA>
                          </td>
                          <td>
                            {board.created_at.slice(0, 10)}
                          </td>
                        </tr>
                      );
                    })}
                  {qnaBoards &&
                    qnaBoards.map((board, key) => {
                      return (
                        <tr>
                          <td>
                            {totalCount-count-key}
                          </td>
                          <td>Q&A</td>
                          <td style={{ textAlign: "left" }} key={key}>
                            <MyA to={`/qnaboard/detail/${board.id}`}>{board.title}</MyA>
                          </td>
                          <td>
                            {board.created_at.slice(0, 10)}
                          </td>
                        </tr>
                      );
                    })}
                {replys &&
                    replys.map((board, key) => {
                      return (
                        <tr>
                          <td>
                          {totalCount-count-qncount-key}
                          </td>
                          <td>댓글</td>
                          <td style={{ textAlign: "left" }} key={key}>
                            <MyA to={`/board/detail/${board.board_post__id}`}>{board.content}</MyA>
                          </td>
                          <td>
                            {board.created_at.slice(0, 10)}
                          </td>
                        </tr>
                      );
                    })}

                </>
              ) : (
                <tr>
                  <td colSpan={4}>등록된 글이 없습니다</td>
                </tr>
              )}
        
            </tbody>
          </table></>):(<>
          
            <table className="mypageBoard">
            <thead>
              <tr>
                <td>
                  <strong>분류</strong>
                </td>
                <td>
                  <strong>제목</strong>
                </td>
                <td>
                  <strong>등록일</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {MBdata.lengthL>0 &&MBdata  ? (
                <>
                {boards &&
                    boards.map((board, key) => {
                      return (
                        <tr>
                          <td>게시판</td>
                          <td style={{ textAlign: "left" }} key={key}>
                            <MyA to={`/board/detail/${board.id}`}>{board.title}</MyA>
                          </td>
                          <td>
                            {board.created_at.slice(5, 10)}
                          </td>
                        </tr>
                      );
                    })}
                  {qnaBoards &&
                    qnaBoards.map((board, key) => {
                      return (
                        <tr>
                          <td>Q&A</td>
                          <td style={{ textAlign: "left" }} key={key}>
                            <MyA to={`/qnaboard/detail/${board.id}`}>{board.title}</MyA>
                          </td>
                          <td>
                            {board.created_at.slice(5, 10)}
                          </td>
                        </tr>
                      );
                    })}
                {replys &&
                    replys.map((board, key) => {
                      return (
                        <tr>
                          <td>댓글</td>
                          <td style={{ textAlign: "left" }} key={key}>
                            <MyA to={`/board/detail/${board.board_post__id}`}>{board.content}</MyA>
                          </td>
                          <td>
                            {board.created_at.slice(5, 10)}
                          </td>
                        </tr>
                      );
                    })}

                </>
              ) : (
                <tr>
                  <td colSpan={4}>등록된 글이 없습니다</td>
                </tr>
              )}
        
            </tbody>
          </table></>)} 
        </div>
        <div className="btn-container d-flex justify-content-between">
          <div></div>
          <div className="btn-wrap d-flex">
            <button type="button" onClick={() => navigate(-1)}>
              뒤로가기
            </button>
          </div>
        </div>
      </ContentLayout>
    </div>
  );
}

export default MypageBoard;
