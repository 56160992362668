import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ContentLayout from "../shared/ContentLayout.js";
import Menulists from "../../data/Menulist.js";
import SubMenubar from "../layout/SubMenubar.js";
import SubImgbar from "../layout/SubImgbar.js";
import "./BoardDetail.css";
import MyA from "../shared/MyA.js";
import { useDelData, useGetUserId ,useAllGetData} from "../../hooks/useApi.js";
function NoticeDetail() {
  const location = useLocation();
  const boardId = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );
  const sub_menubar = Menulists.find(
    (m) => m.url == `/${location.pathname.split("/")[1]}`
  );
  const sub_menulists = Menulists.filter(
    (ml) => ml.mainMenu == sub_menubar.mainMenu
  );
  const [userId, isAdmin, writer] = useGetUserId();
  const [contents, setContents, delContent] = useDelData(
    "/board/notices",
    boardId,
    "/notice"
  );
  const [data] = useAllGetData("/board/notices", boardId);



  // async function getContent() {
  //   const { data } = await axios.get(
  //     `https://admin.miracledream.net/board/notices/${boardId}/`
  //   );
  //   setContents(data);
  // }
  // console.log(contents);
  // useEffect(() => {
    // getContent();
  // }, []);

  //const navigate = useNavigate();
  return (
    <div className="boardDetail">
      <SubImgbar />
      <SubMenubar submenulists={sub_menulists} subtitle={sub_menubar.title} />
      <ContentLayout>
        <div className="boardDetail-wrap">
          {data && (
            <table className="boardDetail">
              <thead>
                <tr>
                  <td colSpan={4}>
                    <strong>{data.title}</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={3}>작성자 : admin </td>
                <td style={{ textAlign: "right" }}>
                  작성일 : {data.created_at.slice(0, 10)}
                </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{ __html: data.content }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <div className="boardBtn-wrap">
          {isAdmin?<> <MyA to={`/notice/update/${boardId}`}>
              <button type="button" className="list">
                수정
              </button>
            </MyA>
            <button type="button" className="del" onClick={delContent}>
                   삭제
            </button></>: <></>}
            <MyA to="/notice">
              <button type="button" className="list">
                목록
              </button>
            </MyA>
          </div>
        </div>
        {/* <>    <h2>공지사항</h2></>
    
         {content.map((con, key) => {
          {
            return (
              <table className="boardDetail" key={key}>
                <thead>
                  <tr>
                    <td>SUBJECT</td>
                    <td>
                      <strong>{con.title}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>WRITER</td>
                    <td>shoes shop</td>
                  </tr>
                  <tr>
                    <td>DATE</td>
                    <td style={{ color: "grey" }}>{con.regdate}</td>
                    <td>VIEW</td>
                    <td style={{ color: "grey" }}>8207</td>
                  </tr>
                </thead>
                <tbody>
                  <td
                    colSpan={"4"}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <p style={{ marginTop: "20px", marginBottom: "20px" }}>
                      {con.content}
                    </p>
                  </td>
                </tbody> 
              </table>
            );
          }
        })}

        <button
          onClick={() => {
            navigate(-1);
          }}
          className="listBox"
        >
          LIST
        </button>*/}
      </ContentLayout>
    </div>
  );
}

export default NoticeDetail;
