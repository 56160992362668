import "./App.css";
import { useState } from "react";
import {
  BrowserRouter as Brouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
//userpage
import Navbar from "./components/layout/Navbar";
import Main from "./components/pages/Main"
import Introduce from "./components/pages/Introduce";
import CeoIntroduce from "./components/pages/CeoIntroduce"
import Mypage from "./components/pages/Mypage";
import Profile from "./components/pages/Profile";
import NotFound from "./components/pages/NotFound";
import Ai from "./components/pages/Ai";
import Trend from "./components/pages/Trend";
import Map from "./components/pages/Map";
import MypageBoard from "./components/pages/MypageBoard";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Email from "./components/pages/Email";
import SecretChange from "./components/pages/SecretChange";

//Board
import Board from "./components/pages/Board";
import BoardDetail from "./components/pages/BoardDetail";
import BoardInsert from './components/pages/BoardInsert';
import BoardUpdate from "./components/pages/BoardUpdate";
import QNABoard from "./components/pages/QNABoard";
import QNABoardDetail from "./components/pages/QNABoardDetail";
import QNABoardInsert from "./components/pages/QNABoardInsert";
import QNABoardUpdate from "./components/pages/QNABoardUpdate";
import Reference from "./components/pages/Reference";
import ReferenceDetail from "./components/pages/ReferenceDetail";
import ReferenceInsert from "./components/pages/ReferenceInsert";
import ReferenceUpdate from "./components/pages/ReferenceUpdate";
import GalleryUpdate from "./components/pages/GalleryUpdate";
import GalleryInsert from "./components/pages/GalleyInsert";
import Gallery from "./components/pages/Gallery";
import GalleryDetail from "./components/pages/GalleryDetail"
import FNQ from "./components/pages/FNQ"
import Notice from "./components/pages/Notice";
import NoticeDetail from "./components/pages/NoticeDetail"
import NoticeInsert from "./components/pages/NoticeInsert";
import NoticeUpdate from "./components/pages/NoticeUpdate";
//loginpage
import FindId from "./components/pages/FindId"
import Registerconfirm from "./components/pages/Registerconfirm";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";
import { RecoilRoot } from "recoil";
import ProtectedRoute from "./routes/ProtectedRoute";
function App() {
  const [user, setUser] = useState(localStorage.getItem("access_token"));

  return (
    <Brouter>
      <div className="main-container">
        <div className="main">
          <RecoilRoot>
          <Routes>
            <Route element={<Navbar />}>
              <Route path="/" element={<Main />} />
              <Route path="/introduce" element={<Introduce/>}/>
              <Route path="/introduce/ceo" element={<CeoIntroduce/>}/>
              <Route path="/findId" element={<FindId/>}/>
              <Route path="/login" element={<Login />} />
              <Route path="/registerconfirm" element={<Registerconfirm />} />
              <Route path="/register" element={<Register />} />
              <Route path="/ai" element={<Ai/>}/>
              <Route path="/trend" element={<Trend/>}/>
              <Route path="/board" element={<Board />} />
              <Route path="/board/detail/:id" element={<BoardDetail />}/>
              <Route path="/board/update/:id" element={<BoardUpdate />}/>
              <Route path="/qnaboard" element={<QNABoard />}/>
              <Route path="/qnaboard/detail/:id" element={<QNABoardDetail />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/board/insert" element={<BoardInsert />} />
                <Route path="/board/update/:id" element={<BoardUpdate />} />
                <Route path="/qnaboard/insert" element={<QNABoardInsert />} />
                <Route path="/qnaboard/insert/:id" element={<QNABoardInsert />} />
                <Route path="/qnaboard/insert/:id" element={<QNABoardInsert />} />
                <Route path="/qnaboard/update/:id" element={<QNABoardUpdate />} />
                <Route path="/gallery/insert" element={<GalleryInsert />} />
                <Route path="/reference/insert" element={<ReferenceInsert />} />
                <Route path="/mypage" element={<Mypage />} />
                <Route path="/mypage/secretchange" element={<SecretChange />} />
                <Route path="/profile" element={<Profile  />} />
                <Route path="/notice/insert" element={<NoticeInsert />} />
                <Route path="/notice/update/:id" element={<NoticeUpdate />} />
                <Route path="/reference/update/:id" element={<ReferenceUpdate />}/>
                <Route path="/mypageboard" element={<MypageBoard  />} />
              </Route>
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/reference/detail/:id" element={<ReferenceDetail/>}/>

              <Route
                path="/gallery/detail/:id"
                element={<GalleryDetail/>}
              />
              <Route
                path="/gallery/update/:id"
                element={<GalleryUpdate/>}
              />
              <Route path="/location" element={<Map/>}/>
              <Route path="/fnq" element={<FNQ />}/>
              <Route path="/reference" element={<Reference />}/>
            
              <Route path="notice" element={<Notice />} />
              <Route path="notice/detail/:id" element={<NoticeDetail />} />
              
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/emailprivacy" element={<Email />} />
            </Route>
            <Route path="adminlogin" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          </RecoilRoot>
      </div>
      </div>
    </Brouter>
  );
}

export default App;
