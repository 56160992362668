
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Profile.css";
import SubImgbar from "../layout/SubImgbar";
import ContentLayout from "../shared/ContentLayout";
import { useAllGetData, useGetUserId, useUpdateData } from "../../hooks/useApi";

function Profile() {
  const navigate = useNavigate();
  const [userName , setUserName]= useState("");
  const [userPhone, setUserPhone] = useState("");
  const [emailCheck, setEmailCheck] = useState("false");
  const [data] = useAllGetData("/api/user/mypage");
  const inputName = useRef(null);
  const inputPhone = useRef(null);
  const [userId] = useGetUserId();
  const updateData = useUpdateData("/api/user/mypage",userId,"/mypage");


  useEffect(() => {
    if (data && data.length > 0) {
      setEmailCheck(data[0].isEmailCheck?'수신함':'수신안함');
      setUserPhone(data[0].phone_num);
      setUserName(data[0].name);
    }
  }, [data]);

  const emailCheckHandler = (value) => {
    setEmailCheck(value);
  };
  
  const handleRegisterForm = async (e) => {
    e.preventDefault();
    const info ={
      name : inputName.current.value,
      phone_num: inputPhone.current.value,
      isEmailCheck: (emailCheck==="수신함"?true:false)
    }
    updateData(info);
    
  }
  return (
    <div className="profile-wrap">
      <SubImgbar />
      <ContentLayout>
        <div className="profile-box">
          <div className="contentMypageSubtitle">
            <h2 className="contentMypageboard">회원정보수정</h2>
          </div>
          <form
            method="post"
            onSubmit={handleRegisterForm}
            encType="multipart/form-data"
          >
            <div className="profile-title d-flex justify-content-between">
              <h4>기본정보</h4>
              <div style={{ fontSize: "12px" }}>
                <span>*</span> 은 필수입력
              </div>
            </div>
            {data && (
              <table>
                <thead>
                  <tr>
                    <td>
                      아이디 <span>*</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={data[0].email}
                        disabled
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                {/* <tr>
                    <td>
                      기존비밀번호 <span>*</span>
                    </td>
                    <td>
                      <input
                        type="password"
                        defaultValue={userOldPw}
                        ref={inputOldPw}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      새로운 비밀번호 <span>*</span>
                    </td>
                    <td>
                      <input
                        type="password"
                        defaultValue={userPw}
                        ref={inputPw}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <span className="pw-condition">
                        (영문,숫자,특수문자 포함 8자이상 20자이하 입력해주세요)
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      비밀번호확인 <span>*</span>
                    </td>
                    <td>
                      <input
                        type="password"
                        onChange={(e) => setUserChkPw(e.target.value)}
                        required
                      />
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      이름 <span>*</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        defaultValue={userName}
                        ref={inputName}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>휴대전화 <span>*</span></td>
                    <td>
                      <input
                        type="text"
                        defaultValue={userPhone}
                        ref={inputPhone}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      이메일 수신여부 <span>*</span>
                    </td>
                    <td>
                      <div className="checkbox-group">
                        <input
                          type="checkbox"
                          className="email-check"
                          checked={emailCheck === "수신함"}
                          onChange={() => emailCheckHandler("수신함")}
                        />
                        <label> 수신함</label>
                        <input
                          type="checkbox"
                          className="email-check"
                          checked={emailCheck === "수신안함"}
                          onChange={() => emailCheckHandler("수신안함")}
            
                        />
                        <label> 수신안함</label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            <div className="btn-container d-flex justify-content-between">
              <div></div>
              <div className="btn-wrap d-flex">
                <button type="submit">정보수정</button>
                <button type="button" onClick={() => navigate(-1)}>
                  취소
                </button>
              </div>
            </div>
          </form>
        </div>
      </ContentLayout>
    </div>
  );
}

export default Profile;
