import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "./QuillEditor.css";
// import ImageResize  from "quill-image-resize"; 

const QuillEditor = ({value, onChange }) => {

  // if (!Quill.register("modules/imageResize")) {
  //   Quill.register("modules/imageResize", ImageResize);
  // }
  return (
    <div>
     <EditorToolbar/>
      <ReactQuill
        theme="snow" 
        modules={modules}
        formats={formats}
        value={value}
        onChange={onChange}
        className="reactquill"
      />
    </div>
  );
};

export default QuillEditor;
